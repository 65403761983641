import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AUTOMATIC_PAYMENT_SLICE_NAME } from "store/constants";
import { Card, UserCardTypeEnum } from "types";

type TAutomaticPaymentState = {
  propertyId: string;
  cardId: string;
  payerChargeDate: string;
  dayNumber: string;
  force: boolean;
  card: Card;
};

const initialState: TAutomaticPaymentState = {
  propertyId: "",
  cardId: "",
  payerChargeDate: "",
  dayNumber: "1",
  force: false,
  card: {
    cardType: UserCardTypeEnum.Visa,
    cardNumber: "",
    id: "",
    metadata: undefined,
    automaticPayment: false,
  },
};

const automaticPaymentSlice = createSlice({
  name: AUTOMATIC_PAYMENT_SLICE_NAME,
  initialState,
  reducers: {
    setPropertyId(state, action: PayloadAction<string>) {
      state.propertyId = action.payload;
    },
    setCardId(state, action: PayloadAction<string>) {
      state.cardId = action.payload;
    },
    setPayerChargeDate(state, action: PayloadAction<string>) {
      state.payerChargeDate = action.payload;
    },
    setDayNumber(state, action: PayloadAction<string>) {
      state.dayNumber = action.payload;
    },
    setForce(state, action: PayloadAction<boolean>) {
      state.force = action.payload;
    },
    setCard(state, action: PayloadAction<Card>) {
      state.card = action.payload;
    },
    clearAutomaticPayment(state) {
      state.propertyId = "";
      state.cardId = "";
      state.payerChargeDate = "";
      state.dayNumber = "1";
      state.force = false;
    },
  },
});

export const {
  setPropertyId,
  setCardId,
  setPayerChargeDate,
  setDayNumber,
  clearAutomaticPayment,
  setCard,
} = automaticPaymentSlice.actions;

export default automaticPaymentSlice;
