import logger from "redux-logger";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { api } from "./baseApi";
import { api as rersidentsApi } from "./residentsApi";

const ignoredActions: string[] = [
  // redux-persist actions
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  // Add any other actions you want to ignore here
];

const ignoredPaths: string[] = [
  // Add any paths you want to ignore here
];

export const serializableCheck = { ignoredActions, ignoredPaths };

const showLogger =
  process.env.NODE_ENV === "development" && typeof window !== "undefined";

const rootMiddleware = [
  api.middleware,
  rersidentsApi.middleware,
  ...(showLogger ? [logger] : []),
];

export default rootMiddleware;
