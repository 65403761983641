import { useEffect, useRef } from "react";

export default function useBeforeUnload(
  handler: ((event: BeforeUnloadEvent) => string | void) | null
) {
  const enabled = typeof handler === "function";

  const handlerRef = useRef(handler);
  useEffect(() => {
    handlerRef.current = handler;
  });

  useEffect(() => {
    if (enabled) {
      const listener = (event: BeforeUnloadEvent) => {
        const returnValue = handlerRef.current
          ? handlerRef.current(event)
          : null;

        if (typeof returnValue === "string") {
          event.preventDefault();
          // eslint-disable-next-line no-return-assign, no-param-reassign
          return (event.returnValue = returnValue);
        }

        if (event.defaultPrevented) {
          // eslint-disable-next-line no-return-assign, no-param-reassign
          return (event.returnValue = "");
        }

        return undefined;
      };

      window.addEventListener("beforeunload", listener);
      return () => {
        window.removeEventListener("beforeunload", listener);
      };
    }

    return undefined;
  }, [enabled]);
}
