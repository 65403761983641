/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import { createWrapper } from "next-redux-wrapper";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import storage from "./sync_storage";
import rootReducer from "./rootReducer";
import rootMiddleware, { serializableCheck } from "./rootMiddleware";
import { api } from "./baseApi";
import { api as residentsApi } from "./residentsApi";
import {
  AUTH_SLICE_NAME,
  COUNTER_SLICE_NAME,
  LOCALE_SLICE_NAME,
  FORM_SLICE_NAME,
  OWNER_AUTH_SLICE_NAME,
  UTM_SLICE_NAME,
  LESSEE_REGISTER_PROPERTY_SLICE_NAME,
  UNREGISTERED_FORM_SLICE_NAME,
} from "./constants";

export const makeStore = () => {
  const persistConfig = {
    key: "root",
    storage,
    whitelist: [
      AUTH_SLICE_NAME,
      COUNTER_SLICE_NAME,
      FORM_SLICE_NAME,
      LOCALE_SLICE_NAME,
      OWNER_AUTH_SLICE_NAME,
      UTM_SLICE_NAME,
      LESSEE_REGISTER_PROPERTY_SLICE_NAME,
      UNREGISTERED_FORM_SLICE_NAME,
    ],
    blacklist: [api.reducerPath, residentsApi.reducerPath],
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck }).concat(rootMiddleware),
    devTools: process.env.NODE_ENV !== "production",
  });

  return { ...store, __persistor: persistStore(store) };
};

const store = makeStore();

const makeWrapper = () => createWrapper(makeStore);

export const wrapper = makeWrapper();

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
export type AppThunk = ThunkAction<
  ReturnType<typeof store.dispatch>,
  AppState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
