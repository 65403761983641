import { useEffect } from "react";
import { hotjar } from "react-hotjar";

export default function useHotjar() {
  useEffect(() => {
    const hjid = process.env.NEXT_PUBLIC_HJID;
    const hjsv = process.env.NEXT_PUBLIC_HJSV;
    if (hjid && hjsv) {
      hotjar.initialize(parseInt(hjid || "", 10), parseInt(hjsv || "", 10));
    }
  }, []);
}
