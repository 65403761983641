export const DEFAULT_LOCALE = "es";

export const FAQ_URL = "https://www.arriendafeliz.cl/post/preguntas-frecuentes";
export const APP_BANNER_URL = "https://me-qr.com/qAFZlIeh";
export const TERMS_URL = "https://www.arriendafeliz.cl/tyc";
export const LOGIN_CF_URL = `${process.env.NEXT_PUBLIC_CF_URL}/log_in`;
export const MILLAS_LATAM_TERMS_URL =
  "https://www.arriendafeliz.cl/post/promocion-millas-latam-pass";
export const TEMPO_IMAGE_URL =
  "https://cdn.comunidadfeliz.net/mailers/images/img_158.png";
export const AMERICAN_CUP_IMAGE_URL =
  "https://cdn.comunidadfeliz.net/mailers/images/img_168.png";
export const AMERICAN_CUP_MOBILE_IMAGE_URL =
  "https://cdn.comunidadfeliz.net/mailers/images/img_169.png";
export const BCI_IMAGE_URL =
  "https://cdn.comunidadfeliz.net/mailers/images/img_177.png";
export const OAUTH_CF_URL = `${process.env.NEXT_PUBLIC_CF_URL}/auth/af`;

export const ANALYTIC_EVENTS = {
  COUPON_ACTIVATE_START: "coupon_activate_start",
  COUPON_ACTIVATE_SUCCESS: "coupon_activate_success",
  OWNER_SIGN_IN: "button_landlord_in_tenants",
  PAY_GGCC_SUCCESS: "pay_ggcc_success",
  PAY_RENT_SUCCESS: "pay_rent_success",
  REDIRECT_PCT_TO_CF: "redirect_pct_to_cf",
};

export const BCI_SOURCES = {
  SOURCE: "BCIplus_web_ext",
  MEDIUM: "bciplus",
  CAMPAIGN: "BCIplus_ext",
};

export const BCI_DISCOUNT = {
  PERCENTAGE: 0.025,
  MAX_AMOUNT: 10000,
};

const isDevelopment = process.env.NODE_ENV === "development";
const isVercelDevelopment =
  process.env.NEXT_PUBLIC_VERCEL_ENV === "development";

/// 16756 is the id of the one click payment gateway in development
/// 16680 is the id of the one click payment gateway in production
export const ONE_CLICK_PAYMENT_GATEWAY_ID =
  isDevelopment || isVercelDevelopment ? "16756" : "16680";

export const bannerUrl = () => {
  const isAndroidOrIos = /Android|iPhone|iPad/i.test(navigator.userAgent);

  if (isAndroidOrIos) {
    return APP_BANNER_URL;
  }
  return LOGIN_CF_URL;
};

export const CAMPAIGNS = {
  NEW_PAYMENT_BUTTON_ON_SUCCESSFUL_ANONYMOUS_FLOW: {
    utm_source: "AF_pago_exitoso_sin_registro",
    utm_medium: "boton_AF",
    utm_campaign: "boton_hacer_otro_pago",
  },
};
