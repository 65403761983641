// eslint-disable-next-line no-shadow
export enum CategoryPathEnum {
  COMMON_EXPENSE = "gasto-comun",
  LEASE = "arriendo",
}

// eslint-disable-next-line no-shadow
export enum StepPathEnum {
  STEP_1 = "step-1",
  STEP_2 = "step-2",
  STEP_3 = "step-3",
  STEP_4 = "step-4",
}

export function getKeyFromEnumValue<T extends { [key: string]: U }, U>(
  value: U,
  enumObject: T
): keyof T {
  const keys = Object.keys(enumObject) as (keyof T)[];
  const foundKey = keys.find((key) => enumObject[key] === value);
  return foundKey as keyof T;
}

export const EXTERNAL_ROUTES = {
  AF_LANDING: process.env.NEXT_PUBLIC_LANDING_URL,
};

const ROUTES = {
  BACK_OFFICE: {
    LOGIN: "/back_office/login",
    TRANSACTIONS: "/back_office/transactions",
    DISPERSIONS: "/back_office/dispersions",
    DISPERSIONS_CALENDAR: "/back_office/dispersions_calendar",
    OWNERS: "/back_office/owners",
    STATISTICS: "/back_office/statistics",
    CAMPAIGNS: "/back_office/campaigns",
  },
  COMMON_EXPENSE: {
    STEP_1: `/${CategoryPathEnum.COMMON_EXPENSE}/${StepPathEnum.STEP_1}`,
    STEP_2: `/${CategoryPathEnum.COMMON_EXPENSE}/${StepPathEnum.STEP_2}`,
    STEP_3: `/${CategoryPathEnum.COMMON_EXPENSE}/${StepPathEnum.STEP_3}`,
    STEP_4: `/${CategoryPathEnum.COMMON_EXPENSE}/${StepPathEnum.STEP_4}`,
  },
  ERROR: "/error",
  HOME: "/",
  LEASE: {
    STEP_1: `/${CategoryPathEnum.LEASE}/${StepPathEnum.STEP_1}`,
    STEP_2: `/${CategoryPathEnum.LEASE}/${StepPathEnum.STEP_2}`,
    STEP_3: `/${CategoryPathEnum.LEASE}/${StepPathEnum.STEP_3}`,
    STEP_4: `/${CategoryPathEnum.LEASE}/${StepPathEnum.STEP_4}`,
  },
  LOAD_DATA: "/load_data",
  LOGIN: "/ingresa",
  PAYMENT_STATUS: "/payment_status",
  SUCCESSFUL_PAYMENT: "/successful_payment",
  OWNER: {
    DASHBOARD: "/propietario/dashboard",
    EDIT_PROPERTY: "/propietario/editar_propiedad",
    NEW_PROPERTY: "/propietario/nueva_propiedad",
    ONBOARDING: {
      YOUR_ACCOUNT: "/propietario/primeros_pasos/tu_cuenta",
      YOUR_PROPERTY: "/propietario/primeros_pasos/tu_propiedad",
    },
    PROPERTIES: "/propietario/propiedades",
    PROPERTY: "/propietario/propiedad",
    SIGN_IN: "/propietario/ingresa",
    SIGN_UP: "/propietario/registro",
    SUCCESSFUL_ACCOUNT_CREATION: "/propietario/cuenta_creada_con_exito",
    PROFILE: "/propietario/perfil",
    MY_BANK_DATA: "/propietario/mis_datos_bancarios",
    EDIT_BANK_DATA: "/propietario/editar_datos_bancarios",
    RECOVERY_PASSWORD: "/propietario/recuperar_contrasena",
  },
  PAYER: {
    EDIT_BANK_DATA: "/arrendatario/editar_datos_bancarios",
    EDIT_PROPERTY_DATA: "/arrendatario/editar_propiedad",
    INDEX: `/arrendatario`,
    NEW_PROPERTY: {
      INDEX: "/arrendatario/nueva_propiedad",
      BANK_DATA: "/arrendatario/nueva_propiedad/datos_bancarios",
    },
    PAYMENT: {
      INDEX: "/arrendatario/pago",
    },
    PAYMENT_DATA: "/arrendatario/datos_de_pago",
    PROFILE: "/arrendatario/perfil",
    PROPERTIES: "/arrendatario/propiedades",
    PROPERTY: "/arrendatario/propiedad",
    RECOVERY_PASSWORD: "/arrendatario/recuperar_contrasena",
    REGISTER_ACCOUNT: "/arrendatario/registrar_cuenta",
    REGISTER_BANK_DATA: "/arrendatario/agregar_datos_bancarios",
    REGISTER_PROPERTY: "/arrendatario/agregar_propiedad",
    SIGN_IN: "/arrendatario/ingresa",
    SIGN_UP: "/arrendatario/registro",
    SUCCESSFUL_ACCOUNT_CREATION: "/arrendatario/cuenta_creada_con_exito",
  },
  SIGNUP: "/registro",
  STEP: `/step`,
  STEP_1: `/${StepPathEnum.STEP_1}`,
  STEP_2: `/${StepPathEnum.STEP_2}`,
  STEP_3: `/${StepPathEnum.STEP_3}`,
  STEP_4: `/${StepPathEnum.STEP_4}`,
};

export default ROUTES;
