import { useRouter } from "next/router";
import { useEffect } from "react";
import { useAppSelector } from "store";

const useLocaleSwitch = () => {
  const router = useRouter();
  const locale = useAppSelector((state) => state.locale);
  useEffect(() => {
    if (router.locale !== locale) {
      router.push(router.pathname, router.pathname, { locale });
    }
  }, [locale, router]);
};

export default useLocaleSwitch;
