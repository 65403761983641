/* eslint-disable no-param-reassign */
/* linter rule not needed since redux toolkit uses Immer internally */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { AUTH_SLICE_NAME } from "store/constants";

export type AuthState = {
  token: string;
};

const initialState: AuthState = {
  token: "",
};

const authSlice = createSlice({
  name: AUTH_SLICE_NAME,
  initialState,
  reducers: {
    login: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    logout: (state) => {
      state.token = "";
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      state.token = action.payload?.auth.token;
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice;
