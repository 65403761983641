import { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { useRouter } from "next/router";
import useAnalytics from "./useAnalytics";

export default function useMixpanelTrackPageView() {
  const router = useRouter();
  const { logEvent } = useAnalytics();

  useEffect(() => {
    const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
    const handleRouteChange = (url: string) => logEvent("Page view", { url });

    if (mixpanelToken) {
      mixpanel.init(mixpanelToken, { persistence: "localStorage" });

      handleRouteChange(router.pathname);

      router.events.on("routeChangeComplete", handleRouteChange);
    }
    return () => {
      if (mixpanelToken) {
        router.events.off("routeChangeComplete", handleRouteChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
