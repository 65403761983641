export function downloadFileFromBase64(base64: string, fileName: string) {
  const aLink = document.createElement("a");
  aLink.href = base64;
  aLink.download = fileName;
  aLink.style.display = "none";
  document.body.appendChild(aLink);
  aLink.click();
  document.body.removeChild(aLink);
  aLink.remove();
}
