import { Url } from "next/dist/shared/lib/router/router";
import { useRouter } from "next/router";
import { useAppSelector } from "store";
import { UserRoleEnum } from "types";
import { ROUTES, EXTERNAL_ROUTES } from "utils";

export default function useAuth() {
  const { push, asPath } = useRouter();
  const { credentials, role } = useAppSelector((state) => state.owner_auth);

  const isAuthenticated = credentials.accessToken;

  const isOwner =
    role === UserRoleEnum.Payee || role === UserRoleEnum.PayerPayee;
  const isLessee =
    role === UserRoleEnum.Payer || role === UserRoleEnum.PayerPayee;

  const routesWithoutLoginButton = [
    ...Object.values(ROUTES.COMMON_EXPENSE),
    ...Object.values(ROUTES.LEASE),
    ROUTES.STEP,
    ROUTES.ERROR,
    ROUTES.SUCCESSFUL_PAYMENT,
  ];

  const register = () => {
    if (asPath.includes(ROUTES.PAYER.SIGN_IN)) {
      push(ROUTES.PAYER.SIGN_UP);
    } else if (asPath.includes(ROUTES.OWNER.SIGN_IN)) {
      push(ROUTES.OWNER.SIGN_UP);
    } else {
      push(ROUTES.PAYER.SIGN_UP);
    }
  };

  const redirectToLogin = () => push(ROUTES.LOGIN);
  const redirectToSignUp = () => push(ROUTES.SIGNUP);

  const redirect = {
    to: (path: string) => push(path),
    home: () => push(ROUTES.HOME),
    afLanding: () => push(EXTERNAL_ROUTES.AF_LANDING as Url),
    dashboard: () => push(ROUTES.OWNER.DASHBOARD),
    login: () => push(ROUTES.OWNER.SIGN_IN),
    profile: () => push(ROUTES.OWNER.PROFILE),
    myBankData: () => push(ROUTES.OWNER.MY_BANK_DATA),
    register,
    recoveryPassword: () => push(ROUTES.OWNER.RECOVERY_PASSWORD),
    onboardingYourAccount: () => push(ROUTES.OWNER.ONBOARDING.YOUR_ACCOUNT),
    onboardingYourProperty: () => push(ROUTES.OWNER.ONBOARDING.YOUR_PROPERTY),
    properties: () => push(ROUTES.OWNER.PROPERTIES),
    newProperty: () => push(ROUTES.OWNER.NEW_PROPERTY),
    property: (propertyId: string) =>
      push(`${ROUTES.OWNER.PROPERTY}?propertyId=${propertyId}`),
    step: (step: number) => push(`${ROUTES.STEP}-${step}`),
  };

  const payerRedirect = {
    to: (path: string) => push(path),
    login: () => push(ROUTES.PAYER.SIGN_IN),
    register: () => push(ROUTES.PAYER.SIGN_UP),
    successfulAccountCreation: () =>
      push(ROUTES.PAYER.SUCCESSFUL_ACCOUNT_CREATION),
    profile: () => push(ROUTES.PAYER.PROFILE),
    properties: () => push(ROUTES.PAYER.PROPERTIES),
    recoveryPassword: () => push(ROUTES.PAYER.RECOVERY_PASSWORD),
    registerAccount: () => push(ROUTES.PAYER.REGISTER_ACCOUNT),
    registerBankData: () => push(ROUTES.PAYER.REGISTER_BANK_DATA),
    paymentData: () => push(ROUTES.PAYER.PAYMENT_DATA),
    registerProperty: () => push(ROUTES.PAYER.REGISTER_PROPERTY),
    editBankData: ({ id }: { id?: string }) =>
      push(`${ROUTES.PAYER.EDIT_BANK_DATA}?id=${id}`),
    editPropertyData: ({ id }: { id?: string }) =>
      push(`${ROUTES.PAYER.EDIT_PROPERTY_DATA}?id=${id}`),
    newProperty: () => push(ROUTES.PAYER.NEW_PROPERTY.INDEX),
    newPropertyBankData: () => push(ROUTES.PAYER.NEW_PROPERTY.BANK_DATA),
    payment: ({ propertyId }: { propertyId: string }) =>
      push(`${ROUTES.PAYER.PROPERTY}/pagar?p=${propertyId}`),
  };

  const rolePath = () => {
    if (
      asPath.includes(ROUTES.OWNER.SIGN_IN) ||
      asPath.includes(ROUTES.OWNER.SIGN_UP)
    ) {
      return UserRoleEnum.Payee;
    }
    if (
      asPath.includes(ROUTES.PAYER.SIGN_IN) ||
      asPath.includes(ROUTES.PAYER.SIGN_UP)
    ) {
      return UserRoleEnum.Payer;
    }
    return UserRoleEnum.Payer;
  };

  const expired = new Date() > new Date(credentials.expiry * 1000);

  return {
    ROUTES,
    asPath,
    credentials,
    expired,
    isAuthenticated,
    isLessee,
    isOwner,
    payerRedirect,
    redirect,
    role,
    rolePath: rolePath(),
    routesWithoutLoginButton,
    redirectToSignUp,
    redirectToLogin,
  };
}
