export const emailRegex = /^[\w.-]+@[a-zA-Z_-]+(?:\.[a-zA-Z]{2,})+$/;

export const isValidEmail = (value: string): boolean =>
  Boolean(emailRegex.test(value) && value !== "");

export const notEmpty = (value: string): boolean =>
  Boolean(value.trim() !== "");

export const isValidAccountNumber = (value: string): boolean =>
  value.length >= 6;

export const isValidMaxLongAccountNumber = (value: string): boolean =>
  value.length <= 14;

function cleanRut(rut: string) {
  return rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase();
}

export const isValidRut = (value: string): boolean => {
  // SOURCE: https://github.com/jlobos/rut.js/blob/master/index.js
  if (!value) {
    return true;
  }

  // if it starts with 0 we return false
  // so a rut like 00000000-0 will not pass
  if (/^0+/.test(value)) {
    return false;
  }

  if (!/^0*(\d{1,3}(\.?\d{3}){2})-?([\dkK])$/.test(value)) {
    return false;
  }

  const cleanedRut = cleanRut(value);

  let digits = parseInt(cleanedRut.slice(0, -1), 10);
  let checkingFactor = 0;
  let modRed = 1;

  while (digits > 0) {
    // eslint-disable-next-line no-plusplus
    modRed = (modRed + (digits % 10) * (9 - (checkingFactor++ % 6))) % 11; // get current mod 11
    digits = Math.floor(digits / 10); // remove last digit
  }

  const v = modRed > 0 ? `${modRed - 1}` : "K";
  return v === cleanedRut.slice(-1);
};
export const isValidPhoneNumber = (value: string): boolean => {
  const cleanedValue = value.replace(/\s/g, "");
  return (
    (cleanedValue.length <= 15 && Boolean(cleanedValue.length)) ||
    cleanedValue === ""
  );
};
export const isValidCouponCode = (value: string): boolean => {
  const couponCodeRegex = /^[A-Za-z0-9_\-/!@#$%&*()+=]+$/;

  if (!notEmpty(value)) {
    return false;
  }

  if (!couponCodeRegex.test(value)) {
    return false;
  }

  return true;
};
export const isValidPassword = (value: string): boolean => {
  const passwordRegex = /^.{8,16}$/;

  if (!passwordRegex.test(value)) {
    return false;
  }

  return true;
};

export function getStepFromURL(url: string) {
  const regex = /step-(\d+)/;
  const match = url.match(regex);
  return match ? parseInt(match[1], 10) : null;
}
