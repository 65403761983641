import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LOCALE_SLICE_NAME } from "store/constants";
import { DEFAULT_LOCALE } from "utils";

const localeSlice = createSlice({
  name: LOCALE_SLICE_NAME,
  initialState: DEFAULT_LOCALE,
  reducers: {
    setLocale: (_state, action: PayloadAction<string>) => action.payload,
  },
});

export const { setLocale } = localeSlice.actions;

export default localeSlice;
