import { DEFAULT_LOCALE } from "./constants";

type DateSeparator = "-" | "/" | "." | " ";
const DEFAULT_TIMEZONE = "America/Santiago";

interface FormatDateArgs {
  date: Date | string;
  separator?: DateSeparator;
  locale?: string;
  timeZone?: string;
}

export function toDateObject(date: Date | string): Date {
  return typeof date === "string" ? new Date(date) : date;
}

export function formatDateDdMmYyyy({
  date,
  separator = "-",
  locale = DEFAULT_LOCALE,
  timeZone = DEFAULT_TIMEZONE,
}: FormatDateArgs): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone,
  };
  return new Intl.DateTimeFormat(locale, options)
    .formatToParts(toDateObject(date))
    .map(({ type, value }) => (type === "literal" ? separator : value))
    .join("");
}

// Martes 29 de noviembre
export function formatDateDdMmYyyyLiteral({
  date,
  locale = DEFAULT_LOCALE,
}: FormatDateArgs): string {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone: "UTC",
    weekday: "long",
  };
  return new Intl.DateTimeFormat(locale, options)
    .format(toDateObject(date))
    .replace(/^[a-z]/, (letter) => letter.toUpperCase());
}

// 29 de noviembre
export function formatDateDDMmLiteral({
  date,
  locale = DEFAULT_LOCALE,
}: FormatDateArgs): string {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    timeZone: "UTC",
  };
  return new Intl.DateTimeFormat(locale, options).format(toDateObject(date));
}

// Martes 29 de noviembre 18:12 hrs
export function formatDateDdMmYyyyHhMmLiteral({
  date,
  locale = DEFAULT_LOCALE,
}: FormatDateArgs): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    day: "numeric",
    month: "long",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };
  return new Intl.DateTimeFormat(locale, options)
    .format(toDateObject(date))
    .concat(" hrs")
    .replace(/,/g, " ")
    .replace(/^[a-z]/, (letter) => letter.toUpperCase());
}

// 20-19-2023, 18:12:23
export function formatDateDdMmYyyyHhMmSs({
  date,
  locale = DEFAULT_LOCALE,
  timeZone = DEFAULT_TIMEZONE,
}: FormatDateArgs): string {
  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return new Intl.DateTimeFormat(locale, options).format(toDateObject(date));
}

export function formatDateYyyyMmDd({
  date,
  separator = "-",
  locale = DEFAULT_LOCALE,
}: FormatDateArgs): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  return new Intl.DateTimeFormat(locale, options)
    .formatToParts(toDateObject(date))
    .reverse()
    .map(({ type, value }) => (type === "literal" ? separator : value))
    .join("");
}

export function formatDateYyyyMmDdHHmmss({
  date,
  separator = "-",
  locale = DEFAULT_LOCALE,
  timeZone = DEFAULT_TIMEZONE,
}: FormatDateArgs): string {
  const dateOptions: Intl.DateTimeFormatOptions = {
    timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    timeZone,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const formattedDate = new Intl.DateTimeFormat(locale, dateOptions)
    .formatToParts(toDateObject(date))
    .reverse()
    .map(({ type, value }) => (type === "literal" ? separator : value))
    .join("");

  const formattedTime = new Intl.DateTimeFormat(locale, timeOptions).format(
    toDateObject(date)
  );

  return `${formattedDate} ${formattedTime}`;
}

export const today = new Date();
export const monthFirstDay = new Date(today.getFullYear(), today.getMonth(), 1);
export const monthLastDay = new Date(
  today.getFullYear(),
  today.getMonth() + 1,
  0
);

export function monthName(date: string) {
  const formatter = new Intl.DateTimeFormat("es-ES", { month: "long" });
  const month = formatter.format(new Date(date));
  return month.charAt(0).toUpperCase() + month.slice(1);
}

export function getMonthAfterDay(day: number) {
  const currentDate = new Date();
  const currentDay = currentDate.getDate(); // Gets the day of the month (1-31)
  let currentMonth = currentDate.getMonth() + 1; // Gets the month (0-11), so add 1 to convert to (1-12)

  // If the current day is 15 or later, switch to the next month
  if (currentDay >= day) {
    currentMonth += 1;
  }

  // If the current month exceeds December (month 12), wrap back to January (month 1)
  if (currentMonth > 12) {
    currentMonth = 1;
  }

  return {
    currentMonth,
    currentMonthName: monthName(`${currentMonth}`),
  };
}
