import { ReactElement } from "react";
import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "@comunidadfeliz/snackbar";

import { wrapper } from "store";
import { useHotjar, useLocaleSwitch, useMixpanelTrackPageView } from "hooks";
import "../styles/globals.css";
import "../styles/snackbar.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

function App({ children }: { children: ReactElement }) {
  useLocaleSwitch();
  return children;
}

function AppWrapper({ Component, ...rest }: AppProps) {
  useHotjar();
  useMixpanelTrackPageView();
  const { store, props } = wrapper.useWrappedStore(rest);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={store.__persistor}>
        <GoogleOAuthProvider clientId={publicRuntimeConfig.googleClientId}>
          <App>
            <Component {...props.pageProps} />
          </App>
        </GoogleOAuthProvider>
        <ToastContainer />
      </PersistGate>
    </Provider>
  );
}

export default appWithTranslation(AppWrapper);
