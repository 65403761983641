import toast from "@comunidadfeliz/snackbar";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getGraphqlErrorMessage = (error: any): string =>
  error.message.split(": {")[0];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorMessage = (error: any): string => {
  const graphqlErrorMessage = getGraphqlErrorMessage(error);

  if (!graphqlErrorMessage && error instanceof Error) {
    return error.message;
  }

  return graphqlErrorMessage;
};
type CommonMutationResponse<T> = T & {
  success: boolean;
  message: string;
};

export const validateGraphqlMutationResponse = <T>(
  response: T | null | undefined,
  defaultErrorMessage: string
): T => {
  if (!response) {
    throw new Error(defaultErrorMessage);
  }
  if (!(response as CommonMutationResponse<T>).success) {
    throw new Error((response as CommonMutationResponse<T>).message);
  }

  return response;
};

export const errorToast = (error: unknown) => {
  toast({
    type: "error",
    message: getErrorMessage(error),
  });
};
