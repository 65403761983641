export const rutFormatter = (value = "") => {
  let rut = value.replace(/[^0-9kK]/g, "");

  if (rut.length > 9) {
    rut = rut.slice(0, 9);
  }

  let verificationDigit = rut.slice(-1);
  let rutDigits = rut.slice(0, -1);
  rutDigits = rutDigits.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

  if (verificationDigit.toLowerCase() === "k") {
    verificationDigit = "K";
  }

  let formattedRut = `${rutDigits}-${verificationDigit}`;

  if (formattedRut.endsWith("-")) {
    formattedRut = formattedRut.slice(0, -1);
  }

  return formattedRut;
};

export const accountNumberFormatter = (value: string) => {
  return value.replace(/\D/g, "");
};

export const currencyFormatter = (value: string, prefix = "CLP") => {
  const formatter = new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  });

  if (prefix === "CLP" && parseInt(value, 10)) {
    return formatter.format(parseInt(value, 10));
  }

  const currency = {
    CLP: "$",
    UF: "UF",
  }[prefix];

  const formattedValue = value
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, "");

  return `${currency} ${formattedValue}`;
};

export const removeCurrency = (value: string) => {
  return value.replace(/[^0-9]/g, "");
};

export const phoneFormatter = (value: string) => {
  if (value.length < 1) {
    return value;
  }
  return `+${value.replace(/\D/g, "")}`;
};

export const removeNonNumeric = (value: string) => {
  return value.replace(/[^0-9]/g, "");
};

export const secureAccountNumber = (value: string) => {
  const secureAccountNumberRegex = /\d(?=\d{4})/g;
  return value.replace(secureAccountNumberRegex, "*");
};

export const codeFormatter = (value: string) => {
  return value.replace(/[^a-zA-Z0-9]/g, "");
};

// remove all non-numeric characters except for coma
export const numberFormatter = (value?: string) => {
  if (!value) {
    return 0;
  }

  return Number(removeNonNumeric(value.split(",")[0]));
};
