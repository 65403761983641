import CryptoJS, { AES } from "crypto-js";

const secretKey = process.env.NEXT_PUBLIC_EMAIL_SECRET_KEY as string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const encryptData = (data: any) =>
  AES.encrypt(JSON.stringify(data), secretKey).toString();

export const decryptData = (token: string) => {
  const bytes = AES.decrypt(token, secretKey);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};
