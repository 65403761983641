import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GUEST_PAYMENT_LOG_ID_SLICE_NAME } from "store/constants";

type State = {
  guestPaymentLogId: string | null;
};

const initialState: State = {
  guestPaymentLogId: null,
};

const guestPaymentLogIdSlice = createSlice({
  name: GUEST_PAYMENT_LOG_ID_SLICE_NAME,
  initialState,
  reducers: {
    clearForm: () => initialState,
    updateField: <T extends keyof State>(
      state: State,
      action: PayloadAction<{ field: T; value: State[T] }>
    ) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
  },
});

export const { updateField, clearForm } = guestPaymentLogIdSlice.actions;

export default guestPaymentLogIdSlice;
