import mixpanel, { Dict } from "mixpanel-browser";

export default function useAnalytics() {
  const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

  const logEvent = (eventName: string, eventOptions: Dict = {}) => {
    if (mixpanelToken) {
      mixpanel.track(eventName, { ...eventOptions });
    }
  };

  return { logEvent };
}
