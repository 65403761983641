/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UNREGISTERED_FORM_SLICE_NAME } from "store/constants";
import {
  BankAccountTypeEnum,
  BankEnum,
  CategoryEnum,
  CreatePayeeInput,
  CreatePayerInput,
  CreatePropertyInput,
  CurrencyEnum,
  FrequencyEnum,
  RecurringInvoiceDataInput,
  UpdatePayeeBankDataInput,
} from "types/schema";
import { getMonthAfterDay } from "utils";

type FormState = {
  category: CategoryEnum;
  month: string;
  payer: CreatePayerInput;
  property: CreatePropertyInput;
  payee: CreatePayeeInput;
  bankData: Omit<UpdatePayeeBankDataInput, "id">;
  recurringInvoice: RecurringInvoiceDataInput;
};

const initialState: FormState = {
  category: CategoryEnum.Lease,
  month: `${getMonthAfterDay(15).currentMonth}`,
  payer: {
    name: "",
    email: "",
    phone: "",
    tin: "",
  },
  payee: {
    name: "",
    email: "",
    tin: "",
  },
  bankData: {
    bank: BankEnum.BancoBice,
    bankAccountType: BankAccountTypeEnum.Current,
    bankAccountNumber: "",
  },
  property: {
    communityAddress: "",
    amount: 0,
    currency: CurrencyEnum.Clp,
    name: "",
  },
  recurringInvoice: {
    amount: 0,
    dayIndicator: 0,
    currency: CurrencyEnum.Clp,
    frequency: FrequencyEnum.Monthly,
    immediateInvoice: false,
    payeeId: "",
    payerId: "",
    propertyId: "",
    startDate: undefined,
    endDate: undefined,
  },
};

const formSlice = createSlice({
  name: UNREGISTERED_FORM_SLICE_NAME,
  initialState,
  reducers: {
    setPayerName: (state, action: PayloadAction<string>) => {
      state.payer.name = action.payload;
    },
    setPayerEmail: (state, action: PayloadAction<string>) => {
      state.payer.email = action.payload;
    },
    setPayerPhone: (state, action: PayloadAction<string>) => {
      state.payer.phone = action.payload;
    },
    setPayerTin: (state, action: PayloadAction<string>) => {
      state.payer.tin = action.payload;
    },
    setPropertyCommunityAddress: (state, action: PayloadAction<string>) => {
      state.property.communityAddress = action.payload;
    },
    setPropertyAmount: (state, action: PayloadAction<string>) => {
      state.property.amount = Number(action.payload);
    },
    setPropertyCurrency: (state, action: PayloadAction<CurrencyEnum>) => {
      state.property.currency = action.payload;
    },
    setPropertyName: (state, action: PayloadAction<string>) => {
      state.property.name = action.payload;
    },
    setRecurringInvoiceData: (
      state,
      action: PayloadAction<RecurringInvoiceDataInput>
    ) => {
      state.recurringInvoice = action.payload;
    },
    setCategory: (state, action: PayloadAction<CategoryEnum>) => {
      state.category = action.payload;
    },
    setMonth: (state, action: PayloadAction<string>) => {
      state.month = action.payload;
    },
    setPayeeName: (state, action: PayloadAction<string>) => {
      state.payee.name = action.payload;
    },
    setPayeeEmail: (state, action: PayloadAction<string>) => {
      state.payee.email = action.payload;
    },
    setPayeeTin: (state, action: PayloadAction<string>) => {
      state.payee.tin = action.payload;
    },
    setBank: (state, action: PayloadAction<BankEnum>) => {
      state.bankData.bank = action.payload;
    },
    setAccountType: (state, action: PayloadAction<BankAccountTypeEnum>) => {
      state.bankData.bankAccountType = action.payload;
    },
    setAccountNumber: (state, action: PayloadAction<string>) => {
      state.bankData.bankAccountNumber = action.payload;
    },
    fillState: (state, { payload }: PayloadAction<Partial<FormState>>) => {
      Object.assign(state, {
        ...initialState,
        ...payload,
      });
    },
    clearForm: () => initialState,
  },
});

export const {
  setPayerName,
  setPayerEmail,
  setPayerPhone,
  setPayerTin,
  setPropertyCommunityAddress,
  setPropertyAmount,
  setPropertyCurrency,
  setPropertyName,
  setRecurringInvoiceData,
  setCategory,
  setMonth,
  setPayeeName,
  setPayeeEmail,
  setPayeeTin,
  setBank,
  setAccountType,
  setAccountNumber,
  fillState,
  clearForm,
} = formSlice.actions;

export default formSlice;
