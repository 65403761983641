import { useAppSelector } from "store";

export default function useStepValidator() {
  const { payer, property, bankData, payee } = useAppSelector(
    (state) => state.unregistered_form
  );

  const stepOneValidation = Boolean(payer.name && payer.email && payer.tin);
  const stepTwoValidation = Boolean(
    property.amount && property.name && property.currency
  );
  const stepThreeValidation =
    Boolean(
      bankData.bank && bankData.bankAccountNumber && bankData.bankAccountType
    ) && Boolean(payee.name && payee.email && payee.tin);

  return { stepOneValidation, stepTwoValidation, stepThreeValidation };
}
