export const AUTH_SLICE_NAME = "auth";
export const AUTOMATIC_PAYMENT_SLICE_NAME = "automatic_payment";
export const BCI_SLICE_NAME = "bci";
export const CARDS_SLICE_NAME = "cards";
export const COUNTER_SLICE_NAME = "counter";
export const FORM_SLICE_NAME = "form";
export const GUEST_PAYMENT_LOG_ID_SLICE_NAME = "guest_payment_log_id";
export const LESSEE_REGISTER_PROPERTY_SLICE_NAME = "lessee_register_property";
export const LOCALE_SLICE_NAME = "locale";
export const MODALS_SLICE_NAME = "modals";
export const ONBOARDING_FORM_NAME = "onboarding_form";
export const ONBOARDING_PROPERTY_FORM_NAME = "onboarding_property_form";
export const OWNER_AUTH_SLICE_NAME = "owner_auth";
export const SUCCESSFUL_PAYMENT_SLICE_NAME = "successful_payment";
export const UNREGISTERED_FORM_SLICE_NAME = "unregistered_form";
export const UTM_SLICE_NAME = "utm";
