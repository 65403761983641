import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MODALS_SLICE_NAME } from "store/constants";

const initialState = {
  automaticPayment: {
    alreadySubscribedCard: false,
    subscribeAutomaticPayment: false,
    unsubscribeCard: false,
  },
};

const modalsSlice = createSlice({
  name: MODALS_SLICE_NAME,
  initialState,
  reducers: {
    setAlreadySubscribedCardModal: (state, action: PayloadAction<boolean>) => {
      state.automaticPayment.alreadySubscribedCard = action.payload;
    },
    setSubscribeAutomaticPaymentModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.automaticPayment.subscribeAutomaticPayment = action.payload;
    },
    setUnsubscribeCardModal: (state, action: PayloadAction<boolean>) => {
      state.automaticPayment.unsubscribeCard = action.payload;
    },
  },
});

export const {
  setAlreadySubscribedCardModal,
  setSubscribeAutomaticPaymentModal,
  setUnsubscribeCardModal,
} = modalsSlice.actions;

export default modalsSlice;
