import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UTM_SLICE_NAME } from "store/constants";
import { StepEnum } from "types";

export type UtmState = {
  step?: StepEnum;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_id?: string;
  paymentId?: string;
  lastUtm?: {
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
    utm_id?: string;
  };
};

const initialState: UtmState = {
  step: StepEnum.Visit,
  utm_source: "",
  utm_medium: "",
  utm_campaign: "",
  utm_id: "",
  paymentId: "",
  lastUtm: undefined,
};

const utmSlice = createSlice({
  name: UTM_SLICE_NAME,
  initialState,
  reducers: {
    setUtmData(state, action: PayloadAction<UtmState>) {
      state.utm_source = action.payload.utm_source;
      state.utm_medium = action.payload.utm_medium;
      state.utm_campaign = action.payload.utm_campaign;
      state.utm_id = action.payload.utm_id;
    },
    setStep(state, action: PayloadAction<StepEnum>) {
      state.step = action.payload;
    },
    setPaymentId(state, action: PayloadAction<string>) {
      state.paymentId = action.payload;
    },
    setLastUtm(
      state,
      action: PayloadAction<{
        utm_source: string;
        utm_medium: string;
        utm_campaign: string;
        utm_id?: string;
      }>
    ) {
      state.lastUtm = action.payload;
    },
  },
});

export const { setUtmData, setStep, setPaymentId, setLastUtm } =
  utmSlice.actions;

export default utmSlice;
