import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";
import { HYDRATE } from "next-redux-wrapper";

export const resclient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_WEB_URL}/graphql`
);

export const residentsQuery = graphqlRequestBaseQuery({
  url: `${process.env.NEXT_PUBLIC_WEB_URL}/graphql`,
  prepareHeaders: (headers: Headers): Headers => {
    headers.set("Content-Type", "application/json");
    headers.set("SOURCE", "residents");
    return headers;
  },
});

export const api = createApi({
  baseQuery: residentsQuery,
  reducerPath: "rersidentsApi",
  // eslint-disable-next-line consistent-return
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  tagTypes: [],
  endpoints: () => ({}),
});
