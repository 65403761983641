import { useState } from "react";

function usePaginator(initialRowsPerPage = 30) {
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowPerPage] = useState<number>(initialRowsPerPage);
  const [after, setAfter] = useState<string>("");
  const [before, setBefore] = useState<string>("");
  const [position, setPosition] = useState<boolean>(true);

  const setNextPage = (cursor: string) => {
    setPage(page + 1);
    setBefore("");
    setAfter(cursor);
    setPosition(true);
  };

  const setPrevPage = (cursor: string) => {
    setPage(page - 1);
    setAfter("");
    setBefore(cursor);
    setPosition(false);
  };

  const setRowsPerPage = (option: number) => {
    setPage(1);
    setRowPerPage(option);
    setPosition(true);
    setBefore("");
    setAfter("");
  };

  const resetPaginator = () => {
    setPage(1);
    setPosition(true);
    setBefore("");
    setAfter("");
  };

  return {
    page,
    rowsPerPage,
    after,
    before,
    position,
    setPage,
    setNextPage,
    setPrevPage,
    setRowsPerPage,
    resetPaginator,
  };
}

export default usePaginator;
