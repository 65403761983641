/* eslint-disable no-shadow */
import { BankAccountTypeEnum, PayTypeEnum, BankEnum } from "./schema";

export type TDropdownKey = BankEnum | BankAccountTypeEnum | number | string;

export const paymentTypes = {
  credit: PayTypeEnum.CreditCard,
  debit: PayTypeEnum.DebitCard,
};

export type TPaymentType = keyof typeof paymentTypes;

export enum OrderStatusEnum {
  fullfilled = "fullfilled",
  created = "created",
}

export enum PaymentStatusEnum {
  approved = "approved",
  failed = "failed",
}

export type TOrderStatus = keyof typeof OrderStatusEnum;
export type TPaymentStatus = keyof typeof PaymentStatusEnum;
