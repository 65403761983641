import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SUCCESSFUL_PAYMENT_SLICE_NAME } from "store/constants";

export type SuccessfulPaymentState = { showVoucher: boolean };

const initialState: SuccessfulPaymentState = {
  showVoucher: false,
};

const successfulPaymentSlice = createSlice({
  name: SUCCESSFUL_PAYMENT_SLICE_NAME,
  initialState,
  reducers: {
    setShowVoucher: (state, action: PayloadAction<boolean>) => {
      state.showVoucher = action.payload;
    },
  },
});

export const { setShowVoucher } = successfulPaymentSlice.actions;

export default successfulPaymentSlice;
