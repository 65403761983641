export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Email: any;
  ISO8601Date: any;
  ISO8601DateTime: any;
  JSON: any;
};

export type AfCard = {
  __typename?: 'AfCard';
  cardType: Scalars['String'];
  externalId: Scalars['ID'];
  lastDigits: Scalars['String'];
  lastPaymentId?: Maybe<Scalars['ID']>;
  paymentGatewayId: Scalars['ID'];
};

export enum AttemptStatusEnum {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Voided = 'VOIDED'
}

export type AutomaticPaymentDates = {
  __typename?: 'AutomaticPaymentDates';
  firstChargeDate: Scalars['ISO8601Date'];
  firstPayDate: Scalars['ISO8601Date'];
  nextChargeDate: Scalars['ISO8601Date'];
  nextPayDate: Scalars['ISO8601Date'];
};

export type AutomaticPaymentReminder = {
  __typename?: 'AutomaticPaymentReminder';
  active: Scalars['Boolean'];
  cardId: Scalars['ID'];
  dayNumber: Scalars['Int'];
  id: Scalars['ID'];
  payerChargeDate: Scalars['ISO8601Date'];
  propertyId: Scalars['ID'];
  status: AutomaticPaymentReminderStatusEnum;
  userId: Scalars['ID'];
};

export enum AutomaticPaymentReminderStatusEnum {
  Created = 'CREATED',
  Deleted = 'DELETED',
  Done = 'DONE',
  Error = 'ERROR',
  Failed = 'FAILED',
  Replaced = 'REPLACED',
  Running = 'RUNNING',
  Unsubscribed = 'UNSUBSCRIBED'
}

export type Balance = {
  __typename?: 'Balance';
  convertedAmount?: Maybe<Scalars['Float']>;
  convertedAmountStr?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastTransaction?: Maybe<BusinessTransaction>;
  total?: Maybe<Scalars['Float']>;
  totalStr?: Maybe<Scalars['String']>;
};

export enum BankAccountTypeEnum {
  Current = 'CURRENT',
  Electronic = 'ELECTRONIC',
  Savings = 'SAVINGS',
  Sight = 'SIGHT'
}

export type BankDataInput = {
  /** Name of the bank */
  bank: BankEnum;
  /** Account number at bank */
  bankAccountNumber: Scalars['String'];
  /** Account type at bank */
  bankAccountType: BankAccountTypeEnum;
};

export enum BankEnum {
  BancoBice = 'BANCO_BICE',
  BancoBtgPactualChile = 'BANCO_BTG_PACTUAL_CHILE',
  BancoConsorcio = 'BANCO_CONSORCIO',
  BancoDelEstadoDeChile = 'BANCO_DEL_ESTADO_DE_CHILE',
  BancoDesarrollo = 'BANCO_DESARROLLO',
  BancoDeChile = 'BANCO_DE_CHILE',
  BancoDeCreditoEInversionesBci = 'BANCO_DE_CREDITO_E_INVERSIONES_BCI',
  BancoFalabella = 'BANCO_FALABELLA',
  BancoInternacional = 'BANCO_INTERNACIONAL',
  BancoItauChile = 'BANCO_ITAU_CHILE',
  BancoRipley = 'BANCO_RIPLEY',
  BancoSantanderChile = 'BANCO_SANTANDER_CHILE',
  BancoSecurity = 'BANCO_SECURITY',
  BankOfChina = 'BANK_OF_CHINA',
  ChinaConstructionBank = 'CHINA_CONSTRUCTION_BANK',
  HsbcBank = 'HSBC_BANK',
  JpMorganChaseBank = 'JP_MORGAN_CHASE_BANK',
  ScotiabankChile = 'SCOTIABANK_CHILE'
}

export type BanksTable = {
  __typename?: 'BanksTable';
  /** Code name of the bank */
  key: BankEnum;
  /** Name of the bank */
  label: Scalars['String'];
  /** SBIF code of the bank */
  sbif: Scalars['String'];
};

/** The connection type for BanksTable. */
export type BanksTableConnection = {
  __typename?: 'BanksTableConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BanksTableEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BanksTable>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BanksTableEdge = {
  __typename?: 'BanksTableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BanksTable>;
};

export type BusinessTransaction = {
  __typename?: 'BusinessTransaction';
  amount: Scalars['Float'];
  amountStr?: Maybe<Scalars['String']>;
  convertedAmount?: Maybe<Scalars['Float']>;
  convertedAmountStr?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  invoice?: Maybe<Invoice>;
  payment?: Maybe<Payment>;
};

export type CalculatePaymentAmountInput = {
  /** Amount without the commission */
  amount: Scalars['Float'];
  /** Coupons and payer email */
  couponsData?: InputMaybe<CouponsDataInput>;
  /** Payment gateway ID */
  paymentGatewayId: Scalars['ID'];
};

export type Campaign = {
  __typename?: 'Campaign';
  accumulatedDiscount: Scalars['Float'];
  active: Scalars['Boolean'];
  allowDiscountExceedCommission: Scalars['Boolean'];
  couponsCount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  discountAmount?: Maybe<Scalars['Float']>;
  discountType: DiscountTypeEnum;
  endAt?: Maybe<Scalars['ISO8601DateTime']>;
  exclusive: Scalars['Boolean'];
  id: Scalars['ID'];
  maxAccumulatedDiscount?: Maybe<Scalars['Float']>;
  maxCouponDiscount?: Maybe<Scalars['Float']>;
  maxQuantityCoupons?: Maybe<Scalars['Int']>;
  maxUsesPerCoupon?: Maybe<Scalars['Int']>;
  maxUsesPerUser?: Maybe<Scalars['Int']>;
  minAmountApplyDiscount?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  specialRule: SpecialRuleEnum;
  startAt: Scalars['ISO8601DateTime'];
  usedCoupons: Scalars['Int'];
};

export type CampaignsTable = {
  __typename?: 'CampaignsTable';
  active: Scalars['Boolean'];
  endAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  startAt: Scalars['ISO8601DateTime'];
};

/** The connection type for CampaignsTable. */
export type CampaignsTableConnection = {
  __typename?: 'CampaignsTableConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CampaignsTableEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CampaignsTable>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CampaignsTableEdge = {
  __typename?: 'CampaignsTableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CampaignsTable>;
};

export type Card = {
  __typename?: 'Card';
  automaticPayment: Scalars['Boolean'];
  cardNumber: Scalars['String'];
  cardType: UserCardTypeEnum;
  id: Scalars['ID'];
  metadata: Scalars['JSON'];
};

export enum CardTypeEnum {
  Bv = 'BV',
  Nc = 'NC',
  Pv = 'PV',
  S2 = 'S2',
  Si = 'SI',
  Vc = 'VC',
  Vd = 'VD',
  Vn = 'VN',
  Vp = 'VP',
  Yes = 'YES'
}

export enum CategoryEnum {
  CommonExpense = 'COMMON_EXPENSE',
  Lease = 'LEASE'
}

export type CfAuth = {
  __typename?: 'CfAuth';
  redirectPath: Scalars['String'];
  user?: Maybe<User>;
};

/** Autogenerated return type of CfAuth. */
export type CfAuthPayload = {
  __typename?: 'CfAuthPayload';
  data: CfAuth;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CommunityExistsInComunidadFeliz = {
  __typename?: 'CommunityExistsInComunidadFeliz';
  communityExists: Scalars['Boolean'];
};

export type Contestant = {
  __typename?: 'Contestant';
  answer: Scalars['String'];
  contestName: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
};

export enum ContestantTypeEnum {
  Payment = 'PAYMENT'
}

export type Coupon = {
  __typename?: 'Coupon';
  campaign: Campaign;
  code: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  discountAmount?: Maybe<Scalars['Float']>;
  expirationAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  userEmail?: Maybe<Scalars['Email']>;
};

export type CouponPayment = {
  __typename?: 'CouponPayment';
  coupon: Coupon;
  discountedAmount: Scalars['Float'];
  id: Scalars['ID'];
};

export type CouponValidatorResponse = {
  __typename?: 'CouponValidatorResponse';
  campaignId?: Maybe<Scalars['ID']>;
  exclusive?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  valid: Scalars['Boolean'];
};

export type CouponsData = {
  __typename?: 'CouponsData';
  campaignId?: Maybe<Scalars['ID']>;
  code: Scalars['String'];
  couponId?: Maybe<Scalars['ID']>;
  discount: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
};

export type CouponsDataInput = {
  /** Discount promo code */
  coupons?: InputMaybe<Array<Scalars['String']>>;
  /** Email of payer */
  payerEmail: Scalars['Email'];
};

export type CouponsDetailsInput = {
  campaignId: Scalars['ID'];
  code: Scalars['String'];
  couponId?: InputMaybe<Scalars['ID']>;
  discount: Scalars['Float'];
  message?: InputMaybe<Scalars['String']>;
};

export type CouponsTable = {
  __typename?: 'CouponsTable';
  attempts: Scalars['Int'];
  code: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  discountAmount?: Maybe<Scalars['Float']>;
  expirationAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  userEmail?: Maybe<Scalars['String']>;
};

/** The connection type for CouponsTable. */
export type CouponsTableConnection = {
  __typename?: 'CouponsTableConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CouponsTableEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CouponsTable>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CouponsTableEdge = {
  __typename?: 'CouponsTableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CouponsTable>;
};

export type CouponsTableFilterInput = {
  /** Filters by campaign of the coupon */
  campaignId?: InputMaybe<Scalars['String']>;
  /** Filters by code of the coupon */
  search?: InputMaybe<Scalars['String']>;
};

export type CreateAutomaticPaymentReminderInput = {
  /** OneClick Card id from Payments Portal */
  cardId: Scalars['ID'];
  /** The day of the month the owner needs the payment */
  dayNumber: Scalars['Int'];
  /** Forces reminder creation even if it already exists */
  force?: InputMaybe<Scalars['Boolean']>;
  /** The first date to execute the reminder */
  payerChargeDate: Scalars['ISO8601Date'];
  /** ID of the property */
  propertyId: Scalars['ID'];
};

/** Autogenerated return type of CreateAutomaticPaymentReminder. */
export type CreateAutomaticPaymentReminderPayload = {
  __typename?: 'CreateAutomaticPaymentReminderPayload';
  data: AutomaticPaymentReminder;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreateCampaignInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  allowDiscountExceedCommission?: InputMaybe<Scalars['Boolean']>;
  discountAmount?: InputMaybe<Scalars['Float']>;
  discountType: DiscountTypeEnum;
  endAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  exclusive?: InputMaybe<Scalars['Boolean']>;
  maxAccumulatedDiscount?: InputMaybe<Scalars['Float']>;
  maxCouponDiscount?: InputMaybe<Scalars['Float']>;
  maxQuantityCoupons?: InputMaybe<Scalars['Int']>;
  maxUsesPerCoupon?: InputMaybe<Scalars['Int']>;
  maxUsesPerUser?: InputMaybe<Scalars['Int']>;
  minAmountApplyDiscount?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  specialRule?: InputMaybe<SpecialRuleEnum>;
  startAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of CreateCampaign. */
export type CreateCampaignPayload = {
  __typename?: 'CreateCampaignPayload';
  data: Campaign;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Autogenerated return type of CreateCard. */
export type CreateCardPayload = {
  __typename?: 'CreateCardPayload';
  data: AfCard;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreateCfCouponInput = {
  amountPaid: Scalars['Float'];
  campaignId: Scalars['ID'];
  userEmail: Scalars['Email'];
};

/** Autogenerated return type of CreateCfCoupon. */
export type CreateCfCouponPayload = {
  __typename?: 'CreateCfCouponPayload';
  data?: Maybe<Coupon>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreateContestantInput = {
  /** Answer of the contestant */
  answer: Scalars['String'];
  /** ID of the contest */
  contestId: Scalars['ID'];
  /** ID of the contestant */
  participantId: Scalars['ID'];
  /** Type of the contestant */
  participantType: ContestantTypeEnum;
};

/** Autogenerated return type of CreateContestant. */
export type CreateContestantPayload = {
  __typename?: 'CreateContestantPayload';
  data: Contestant;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreateCouponInput = {
  /** ID of the campign */
  campaignId: Scalars['ID'];
  /** Code of the coupon */
  code: Scalars['String'];
  /** Fixed discount amount of the coupon */
  discountAmount?: InputMaybe<Scalars['Float']>;
  /** Date until the coupon is valid */
  expirationAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Specific user email for the coupon */
  userEmail?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCoupon. */
export type CreateCouponPayload = {
  __typename?: 'CreateCouponPayload';
  data: Coupon;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreateCouponsListInput = {
  base64Excel: Scalars['String'];
  campaignId: Scalars['ID'];
};

/** Autogenerated return type of CreateCouponsList. */
export type CreateCouponsListPayload = {
  __typename?: 'CreateCouponsListPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreateDispersionInput = {
  /** Date to create new dispersion */
  dispersionDate?: InputMaybe<Scalars['ISO8601Date']>;
};

/** Autogenerated return type of CreateDispersion. */
export type CreateDispersionPayload = {
  __typename?: 'CreateDispersionPayload';
  data: Dispersion;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreatePayeeInput = {
  /** Bank data of the payee */
  bankData?: InputMaybe<BankDataInput>;
  /** Country code of the payee */
  countryCode?: InputMaybe<Scalars['String']>;
  /** Flag to create a new guest payment log */
  createLog?: InputMaybe<Scalars['Boolean']>;
  /** Email of the new payee */
  email: Scalars['Email'];
  /** ID of the guest payment log */
  guestPaymentLogId?: InputMaybe<Scalars['ID']>;
  /** Name of the payee */
  name: Scalars['String'];
  /** ID of the payer */
  payerId?: InputMaybe<Scalars['ID']>;
  /** Tax identification number */
  tin: Scalars['String'];
  /** ID of the user */
  userId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of CreatePayee. */
export type CreatePayeePayload = {
  __typename?: 'CreatePayeePayload';
  data: Payee;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreatePayerInput = {
  /** Flag to create a new guest payment log */
  createLog?: InputMaybe<Scalars['Boolean']>;
  /** Email of the new payer */
  email: Scalars['Email'];
  /** ID of the guest payment log */
  guestPaymentLogId?: InputMaybe<Scalars['ID']>;
  /** Name of the payer */
  name?: InputMaybe<Scalars['String']>;
  /** ID of an existing payee with no payer associated */
  payeeId?: InputMaybe<Scalars['ID']>;
  /** Phone number of the payer */
  phone?: InputMaybe<Scalars['String']>;
  /** Tax identification number */
  tin?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePayer. */
export type CreatePayerPayload = {
  __typename?: 'CreatePayerPayload';
  data: Payer;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreatePaymentInput = {
  /** Amount of the payment */
  amount: Scalars['Float'];
  /** Category of the payment */
  category?: InputMaybe<CategoryEnum>;
  /** Commission for service */
  commission?: InputMaybe<Scalars['Float']>;
  /** True when payer pays commission */
  commissionForPayer?: InputMaybe<Scalars['Boolean']>;
  /** Date of the payment */
  date: Scalars['ISO8601DateTime'];
  /** External property user id */
  externalPropertyUserId?: InputMaybe<Scalars['ID']>;
  /** Payment gateway */
  gateway?: InputMaybe<PaymentGatewayEnum>;
  /** ID of the invoice */
  invoiceId?: InputMaybe<Scalars['ID']>;
  /** ID of the payee */
  payeeId: Scalars['ID'];
  /** ID of the payer */
  payerId: Scalars['ID'];
  /** Period of the payment */
  period: Scalars['ISO8601Date'];
  /** ID of the property */
  propertyId: Scalars['ID'];
};

export type CreatePropertyInput = {
  /** Amount of the property */
  amount?: InputMaybe<Scalars['Float']>;
  /** Address of the community */
  communityAddress: Scalars['String'];
  /** Name of the community */
  communityName?: InputMaybe<Scalars['String']>;
  /** Flag to create a new guest payment log */
  createLog?: InputMaybe<Scalars['Boolean']>;
  /** Currency of the property */
  currency?: InputMaybe<CurrencyEnum>;
  /** ID of the guest payment log */
  guestPaymentLogId?: InputMaybe<Scalars['ID']>;
  /** Name of the property */
  name: Scalars['String'];
  /** Nickname of the property */
  nickname?: InputMaybe<Scalars['String']>;
  /** ID of the payee */
  payeeId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateProperty. */
export type CreatePropertyPayload = {
  __typename?: 'CreatePropertyPayload';
  data: Property;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreateRecurringInvoiceInput = {
  /** Data for the associations creation */
  associations?: InputMaybe<RecurringInvoiceCreateAssociationsInput>;
  /** Data for the recurring invoice */
  recurringInvoice: RecurringInvoiceDataInput;
};

/** Autogenerated return type of CreateRecurringInvoice. */
export type CreateRecurringInvoicePayload = {
  __typename?: 'CreateRecurringInvoicePayload';
  data: RecurringInvoice;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreateUtmLogInput = {
  /** Campaign of the UTM log */
  campaign?: InputMaybe<Scalars['String']>;
  /** City of the UTM log */
  city?: InputMaybe<Scalars['String']>;
  /** Country of the UTM log */
  country?: InputMaybe<Scalars['String']>;
  /** Entry date of the UTM log */
  entryAt: Scalars['ISO8601DateTime'];
  /** Last step of the UTM log */
  lastStep: StepEnum;
  /** Medium of the UTM log */
  medium?: InputMaybe<Scalars['String']>;
  /** ID of the payer */
  payerId?: InputMaybe<Scalars['ID']>;
  /** ID of the payment */
  paymentId?: InputMaybe<Scalars['ID']>;
  /** Source of the UTM log */
  source?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateUtmLog. */
export type CreateUtmLogPayload = {
  __typename?: 'CreateUtmLogPayload';
  data: UtmLog;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Credential = {
  __typename?: 'Credential';
  accessToken: Scalars['String'];
  client: Scalars['String'];
  expiry: Scalars['Int'];
  tokenType: Scalars['String'];
  uid: Scalars['String'];
};

export enum CurrencyEnum {
  Clp = 'CLP',
  Uf = 'UF'
}

export type DateRangeFilterInput = {
  date?: InputMaybe<Scalars['ISO8601Date']>;
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};

export type DecodedInvoice = {
  __typename?: 'DecodedInvoice';
  amount: Scalars['Float'];
  id: Scalars['ID'];
  issuedAt: Scalars['ISO8601Date'];
  payee?: Maybe<Payee>;
  payer?: Maybe<Payer>;
  property?: Maybe<Property>;
};

export type DecodedPayment = {
  __typename?: 'DecodedPayment';
  amount: Scalars['Float'];
  category: CategoryEnum;
  id: Scalars['ID'];
  payee: Payee;
  payer: Payer;
  period: Scalars['ISO8601Date'];
  property: Property;
};

export type DecodedPaymentLog = {
  __typename?: 'DecodedPaymentLog';
  payee?: Maybe<Payee>;
  payer?: Maybe<Payer>;
  payment?: Maybe<Payment>;
  period?: Maybe<Scalars['ISO8601Date']>;
  property?: Maybe<Property>;
};

export type DeleteAutomaticPaymentReminderInput = {
  /** OneClick Card id from Payments Portal */
  cardId: Scalars['ID'];
  /** ID of the user property */
  propertyId: Scalars['ID'];
};

/** Autogenerated return type of DeleteAutomaticPaymentReminder. */
export type DeleteAutomaticPaymentReminderPayload = {
  __typename?: 'DeleteAutomaticPaymentReminderPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteCampaign. */
export type DeleteCampaignPayload = {
  __typename?: 'DeleteCampaignPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteCoupon. */
export type DeleteCouponPayload = {
  __typename?: 'DeleteCouponPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteDispersion. */
export type DeleteDispersionPayload = {
  __typename?: 'DeleteDispersionPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteProperty. */
export type DeletePropertyPayload = {
  __typename?: 'DeletePropertyPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteUserCard. */
export type DeleteUserCardPayload = {
  __typename?: 'DeleteUserCardPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export enum DiscountTypeEnum {
  Fixed = 'FIXED',
  FromCoupon = 'FROM_COUPON',
  Percentage = 'PERCENTAGE'
}

export type Dispersion = {
  __typename?: 'Dispersion';
  id: Scalars['ID'];
  paidDate?: Maybe<Scalars['ISO8601Date']>;
  paymentDate: Scalars['ISO8601Date'];
  payments: Array<Payment>;
  refundDate: Scalars['ISO8601Date'];
  state: DispersionStateEnum;
  totalAmount: Scalars['Float'];
  tracesTxt?: Maybe<DispersionTracesPayload>;
  transactionsExcel?: Maybe<DispersionTransactionsExcelPayload>;
};

export type DispersionCalendarEntriesTable = {
  __typename?: 'DispersionCalendarEntriesTable';
  countryCode: Scalars['String'];
  endAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  paymentDate: Scalars['ISO8601Date'];
  refundDate: Scalars['ISO8601Date'];
  startAt: Scalars['ISO8601DateTime'];
};

/** The connection type for DispersionCalendarEntriesTable. */
export type DispersionCalendarEntriesTableConnection = {
  __typename?: 'DispersionCalendarEntriesTableConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DispersionCalendarEntriesTableEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DispersionCalendarEntriesTable>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DispersionCalendarEntriesTableEdge = {
  __typename?: 'DispersionCalendarEntriesTableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<DispersionCalendarEntriesTable>;
};

export type DispersionCalendarEntry = {
  __typename?: 'DispersionCalendarEntry';
  countryCode: Scalars['String'];
  endAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  paymentDate: Scalars['ISO8601Date'];
  refundDate: Scalars['ISO8601Date'];
  startAt: Scalars['ISO8601DateTime'];
};

export enum DispersionStateEnum {
  Approved = 'APPROVED',
  Paid = 'PAID',
  Rejected = 'REJECTED',
  Sent = 'SENT',
  Standby = 'STANDBY'
}

export type DispersionTracesPayload = {
  __typename?: 'DispersionTracesPayload';
  base64Txt?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
};

export type DispersionTransactionsExcelPayload = {
  __typename?: 'DispersionTransactionsExcelPayload';
  base64Excel?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
};

export type DispersionsTable = {
  __typename?: 'DispersionsTable';
  id: Scalars['ID'];
  paymentDate: Scalars['ISO8601Date'];
  refundDate: Scalars['ISO8601Date'];
  state: DispersionStateEnum;
  totalAmount: Scalars['Float'];
};

/** The connection type for DispersionsTable. */
export type DispersionsTableConnection = {
  __typename?: 'DispersionsTableConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DispersionsTableEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DispersionsTable>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DispersionsTableEdge = {
  __typename?: 'DispersionsTableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<DispersionsTable>;
};

export type EnrollCardInput = {
  /** Callback URL triggered post card registration */
  callback: Scalars['String'];
  /** Payment gateway ID */
  paymentGatewayId: Scalars['ID'];
};

export enum FrequencyEnum {
  Monthly = 'MONTHLY'
}

export type GeneratePaymentVoucherInput = {
  /** Deposit payment */
  isDeposit?: InputMaybe<Scalars['Boolean']>;
  /** Email of the payer */
  payerEmail?: InputMaybe<Scalars['Email']>;
  /** ID of the payment */
  paymentId: Scalars['ID'];
};

/** Autogenerated return type of GeneratePaymentVoucher. */
export type GeneratePaymentVoucherPayload = {
  __typename?: 'GeneratePaymentVoucherPayload';
  data: PaymentVoucherPayload;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type GeneratePaymentsBankReportInput = {
  /** End date of the bank report */
  endAt?: InputMaybe<Scalars['ISO8601Date']>;
  /** Start date of the bank report */
  startAt?: InputMaybe<Scalars['ISO8601Date']>;
};

/** Autogenerated return type of GeneratePaymentsBankReport. */
export type GeneratePaymentsBankReportPayload = {
  __typename?: 'GeneratePaymentsBankReportPayload';
  data: PaymentsBankReportPayload;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Invoice = {
  __typename?: 'Invoice';
  amount: Scalars['Float'];
  amountStr: Scalars['String'];
  currency: CurrencyEnum;
  id: Scalars['ID'];
  issuedAt: Scalars['ISO8601Date'];
  paidAt?: Maybe<Scalars['ISO8601Date']>;
  recurringInvoice?: Maybe<RecurringInvoice>;
};

export enum InvoiceStatusEnum {
  Issued = 'ISSUED',
  Paid = 'PAID',
  ProcessingPayment = 'PROCESSING_PAYMENT'
}

export type Mutation = {
  __typename?: 'Mutation';
  cfAuth?: Maybe<CfAuthPayload>;
  createAutomaticPaymentReminder?: Maybe<CreateAutomaticPaymentReminderPayload>;
  createCampaign?: Maybe<CreateCampaignPayload>;
  createCard?: Maybe<CreateCardPayload>;
  createCfCoupon?: Maybe<CreateCfCouponPayload>;
  createContestant?: Maybe<CreateContestantPayload>;
  createCoupon: CreateCouponPayload;
  createCouponsList: CreateCouponsListPayload;
  createDispersion?: Maybe<CreateDispersionPayload>;
  createPayee?: Maybe<CreatePayeePayload>;
  createPayer?: Maybe<CreatePayerPayload>;
  createProperty?: Maybe<CreatePropertyPayload>;
  createRecurringInvoice?: Maybe<CreateRecurringInvoicePayload>;
  createUtmLog?: Maybe<CreateUtmLogPayload>;
  deleteAutomaticPaymentReminder?: Maybe<DeleteAutomaticPaymentReminderPayload>;
  deleteCampaign?: Maybe<DeleteCampaignPayload>;
  deleteCoupon?: Maybe<DeleteCouponPayload>;
  deleteDispersion?: Maybe<DeleteDispersionPayload>;
  deleteProperty?: Maybe<DeletePropertyPayload>;
  deleteUserCard: DeleteUserCardPayload;
  generatePaymentVoucher?: Maybe<GeneratePaymentVoucherPayload>;
  generatePaymentsBankReport?: Maybe<GeneratePaymentsBankReportPayload>;
  registerPayerPaymentData?: Maybe<RegisterPayerPaymentDataPayload>;
  registerSuccessfulPayment?: Maybe<RegisterSuccessfulPaymentPayload>;
  sendUserPasswordRecoveryEmail?: Maybe<SendUserPasswordRecoveryEmailPayload>;
  sendVoucherMail?: Maybe<SendVoucherMailPayload>;
  signInBackOffice?: Maybe<SignInBackOfficePayload>;
  signInOrSignUpUserOauth?: Maybe<SignInOrSignUpUserOauthPayload>;
  signInUser?: Maybe<SignInUserPayload>;
  signOutUser?: Maybe<SignOutUserPayload>;
  signUpUser?: Maybe<SignUpUserPayload>;
  smartLinkOnboarding?: Maybe<SmartLinkOnboardingPayload>;
  smartLinkPaymentLog?: Maybe<SmartLinkPaymentLogPayload>;
  submitPayment?: Maybe<SubmitPaymentPayload>;
  submitPaymentOneclick?: Maybe<SubmitPaymentOneclickPayload>;
  toggleActiveCampaign?: Maybe<ToggleActiveCampaignPayload>;
  updateCampaign?: Maybe<UpdateCampaignPayload>;
  updateCoupon: UpdateCouponPayload;
  updateDispersionCalendarEntry?: Maybe<UpdateDispersionCalendarEntryPayload>;
  updateDispersionState?: Maybe<UpdateDispersionStatePayload>;
  updatePayeeBankData?: Maybe<UpdatePayeeBankDataPayload>;
  updatePayment?: Maybe<UpdatePaymentPayload>;
  updateProfile?: Maybe<UpdateProfilePayload>;
  updateProperty?: Maybe<UpdatePropertyPayload>;
  updateRecurringInvoice?: Maybe<UpdateRecurringInvoicePayload>;
  updateUserPassword?: Maybe<UpdateUserPasswordPayload>;
  updateUserPasswordWithToken?: Maybe<UpdateUserPasswordWithTokenPayload>;
  updateUserPayCommission?: Maybe<UpdateUserPayCommissionPayload>;
  updateUserPayee?: Maybe<UpdateUserPayeePayload>;
  updateUserRole?: Maybe<UpdateUserRolePayload>;
  voidPayment?: Maybe<VoidPaymentPayload>;
};


export type MutationCfAuthArgs = {
  cfAuthToken: Scalars['String'];
};


export type MutationCreateAutomaticPaymentReminderArgs = {
  input: CreateAutomaticPaymentReminderInput;
};


export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};


export type MutationCreateCardArgs = {
  paymentGatewayId: Scalars['ID'];
};


export type MutationCreateCfCouponArgs = {
  input: CreateCfCouponInput;
};


export type MutationCreateContestantArgs = {
  input: CreateContestantInput;
};


export type MutationCreateCouponArgs = {
  input: CreateCouponInput;
};


export type MutationCreateCouponsListArgs = {
  input: CreateCouponsListInput;
};


export type MutationCreateDispersionArgs = {
  input: CreateDispersionInput;
};


export type MutationCreatePayeeArgs = {
  input: CreatePayeeInput;
};


export type MutationCreatePayerArgs = {
  input: CreatePayerInput;
};


export type MutationCreatePropertyArgs = {
  input: CreatePropertyInput;
};


export type MutationCreateRecurringInvoiceArgs = {
  input: CreateRecurringInvoiceInput;
};


export type MutationCreateUtmLogArgs = {
  input: CreateUtmLogInput;
};


export type MutationDeleteAutomaticPaymentReminderArgs = {
  input: DeleteAutomaticPaymentReminderInput;
};


export type MutationDeleteCampaignArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCouponArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDispersionArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePropertyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserCardArgs = {
  cardId: Scalars['ID'];
};


export type MutationGeneratePaymentVoucherArgs = {
  input?: InputMaybe<GeneratePaymentVoucherInput>;
};


export type MutationGeneratePaymentsBankReportArgs = {
  input?: InputMaybe<GeneratePaymentsBankReportInput>;
};


export type MutationRegisterPayerPaymentDataArgs = {
  input: RegisterPayerPaymentDataInput;
};


export type MutationRegisterSuccessfulPaymentArgs = {
  input: RegisterSuccessfulPaymentInput;
};


export type MutationSendUserPasswordRecoveryEmailArgs = {
  email: Scalars['Email'];
};


export type MutationSendVoucherMailArgs = {
  input: SendVoucherMailInput;
};


export type MutationSignInBackOfficeArgs = {
  input: SignInInput;
};


export type MutationSignInOrSignUpUserOauthArgs = {
  input: SignInOrSignUpUserOauthInput;
};


export type MutationSignInUserArgs = {
  input: SignInUserInput;
};


export type MutationSignUpUserArgs = {
  input: SignUpUserInput;
};


export type MutationSmartLinkOnboardingArgs = {
  token: Scalars['String'];
};


export type MutationSmartLinkPaymentLogArgs = {
  token: Scalars['String'];
};


export type MutationSubmitPaymentArgs = {
  input: SubmitPaymentInput;
};


export type MutationSubmitPaymentOneclickArgs = {
  input: SubmitPaymentInput;
};


export type MutationToggleActiveCampaignArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput;
};


export type MutationUpdateCouponArgs = {
  input: UpdateCouponInput;
};


export type MutationUpdateDispersionCalendarEntryArgs = {
  input: UpdateDispersionCalendarEntryInput;
};


export type MutationUpdateDispersionStateArgs = {
  input: UpdateDispersionStateInput;
};


export type MutationUpdatePayeeBankDataArgs = {
  input: UpdatePayeeBankDataInput;
};


export type MutationUpdatePaymentArgs = {
  input: UpdatePaymentInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdatePropertyArgs = {
  input: UpdatePropertyInput;
};


export type MutationUpdateRecurringInvoiceArgs = {
  input: UpdateRecurringInvoiceInput;
};


export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};


export type MutationUpdateUserPasswordWithTokenArgs = {
  input: UpdateUserPasswordWithTokenInput;
};


export type MutationUpdateUserPayCommissionArgs = {
  input: UpdateUserPayCommissionInput;
};


export type MutationUpdateUserPayeeArgs = {
  input: UpdateUserPayeeInput;
};


export type MutationUpdateUserRoleArgs = {
  role: UserRoleEnum;
};


export type MutationVoidPaymentArgs = {
  id: Scalars['ID'];
};

export enum OauthProviderEnum {
  Cf = 'CF',
  Google = 'GOOGLE'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export enum PayTypeEnum {
  Ach = 'ACH',
  Check = 'CHECK',
  CreditCard = 'CREDIT_CARD',
  DebitCard = 'DEBIT_CARD'
}

export type Payee = {
  __typename?: 'Payee';
  bank: BankEnum;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankAccountNumberLastDigits: Scalars['String'];
  bankAccountType?: Maybe<BankAccountTypeEnum>;
  bankStr: Scalars['String'];
  countryCode: Scalars['String'];
  email: Scalars['String'];
  guestPaymentLogId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  tin: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
};

export type PayeePropertyRecurringInvoicePayload = {
  __typename?: 'PayeePropertyRecurringInvoicePayload';
  amount: Scalars['Float'];
  amountStr: Scalars['String'];
  currency: CurrencyEnum;
  id: Scalars['ID'];
  lastInvoice?: Maybe<RecurringInvoiceLastInvoicePayload>;
  nextInvoiceIssuedAt?: Maybe<Scalars['ISO8601Date']>;
};

export type PayeePropertyTable = {
  __typename?: 'PayeePropertyTable';
  alphanumericCode: Scalars['String'];
  communityAddress: Scalars['String'];
  id: Scalars['ID'];
  mainRecurringInvoice?: Maybe<PayeePropertyRecurringInvoicePayload>;
  name: Scalars['String'];
  nickname: Scalars['String'];
  payeeId?: Maybe<Scalars['ID']>;
};

/** The connection type for PayeePropertyTable. */
export type PayeePropertyTableConnection = {
  __typename?: 'PayeePropertyTableConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PayeePropertyTableEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PayeePropertyTable>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PayeePropertyTableEdge = {
  __typename?: 'PayeePropertyTableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PayeePropertyTable>;
};

export type Payer = {
  __typename?: 'Payer';
  email: Scalars['String'];
  guestPaymentLogId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  recurringInvoices?: Maybe<Array<RecurringInvoice>>;
  tin?: Maybe<Scalars['String']>;
};

export type PayerPropertyTable = {
  __typename?: 'PayerPropertyTable';
  amount?: Maybe<Scalars['Float']>;
  amountStr?: Maybe<Scalars['String']>;
  balance?: Maybe<Balance>;
  communityAddress?: Maybe<Scalars['String']>;
  convertedAmount?: Maybe<Scalars['Float']>;
  convertedAmountStr?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyEnum>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  payee?: Maybe<Payee>;
  payeeId?: Maybe<Scalars['ID']>;
  payerId?: Maybe<Scalars['ID']>;
  recurringInvoiceActive: Scalars['Boolean'];
  recurringInvoiceDayIndicator?: Maybe<Scalars['Int']>;
};

/** The connection type for PayerPropertyTable. */
export type PayerPropertyTableConnection = {
  __typename?: 'PayerPropertyTableConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PayerPropertyTableEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PayerPropertyTable>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PayerPropertyTableEdge = {
  __typename?: 'PayerPropertyTableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PayerPropertyTable>;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Float'];
  amountStr: Scalars['String'];
  attemptAt?: Maybe<Scalars['ISO8601DateTime']>;
  attemptStatus: AttemptStatusEnum;
  category: CategoryEnum;
  commission: Scalars['Float'];
  commissionForPayer: Scalars['Boolean'];
  couponPayments?: Maybe<Array<CouponPayment>>;
  date: Scalars['ISO8601DateTime'];
  dispersion?: Maybe<Dispersion>;
  dispersionId?: Maybe<Scalars['ID']>;
  externalPropertyUserId?: Maybe<Scalars['ID']>;
  externalPurchaseOrderId: Scalars['ID'];
  guestPaymentLogId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  invoice?: Maybe<Invoice>;
  payType: PayTypeEnum;
  payee: Payee;
  payeeId: Scalars['ID'];
  payer?: Maybe<Payer>;
  payerId?: Maybe<Scalars['ID']>;
  property: Property;
  propertyId: Scalars['ID'];
  state?: Maybe<PaymentStateEnum>;
};

export type PaymentAmount = {
  __typename?: 'PaymentAmount';
  commission: Scalars['Float'];
  commissionStr: Scalars['String'];
  commissionWithDiscount?: Maybe<Scalars['Float']>;
  commissionWithDiscountStr?: Maybe<Scalars['String']>;
  couponsDiscount?: Maybe<Array<CouponsData>>;
  totalWithCommission: Scalars['Float'];
  totalWithCommissionStr: Scalars['String'];
};

export type PaymentGateway = {
  __typename?: 'PaymentGateway';
  commissionType: Scalars['String'];
  commissionValue: Scalars['Float'];
  id: Scalars['Int'];
  imageUrl: Scalars['String'];
  name: Scalars['String'];
};

export enum PaymentGatewayEnum {
  BciWebpayplus = 'BCI_WEBPAYPLUS',
  BciWebpayOneclick = 'BCI_WEBPAY_ONECLICK',
  Tenpo = 'TENPO',
  Webpayplus = 'WEBPAYPLUS',
  WebpayOneclick = 'WEBPAY_ONECLICK'
}

export enum PaymentStateEnum {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Fullfilled = 'FULLFILLED',
  Paid = 'PAID',
  Refund = 'REFUND',
  Rejected = 'REJECTED',
  Sent = 'SENT',
  Standby = 'STANDBY',
  Voided = 'VOIDED'
}

export type PaymentVoucherPayload = {
  __typename?: 'PaymentVoucherPayload';
  base64Pdf: Scalars['String'];
  fileName: Scalars['String'];
  paymentId: Scalars['ID'];
};

export type PaymentsBankReportPayload = {
  __typename?: 'PaymentsBankReportPayload';
  base64Excel: Scalars['String'];
  fileName: Scalars['String'];
};

export type PaymentsTable = {
  __typename?: 'PaymentsTable';
  amount: Scalars['Float'];
  amountStr: Scalars['String'];
  attemptStatus: AttemptStatusEnum;
  category: CategoryEnum;
  commission: Scalars['Float'];
  commissionStr: Scalars['String'];
  couponPayments: Array<CouponPayment>;
  date: Scalars['ISO8601DateTime'];
  dispersion?: Maybe<Dispersion>;
  externalPurchaseOrderId: Scalars['ID'];
  id: Scalars['ID'];
  invoiceIssuedAt?: Maybe<Scalars['ISO8601Date']>;
  invoiceStatus?: Maybe<InvoiceStatusEnum>;
  loadPaymentSmartlink?: Maybe<Scalars['String']>;
  loadPaymentSmartlinkWithAmount?: Maybe<Scalars['String']>;
  payType: PayTypeEnum;
  payee?: Maybe<Payee>;
  payer?: Maybe<Payer>;
  period?: Maybe<Scalars['ISO8601Date']>;
  promoCode?: Maybe<Scalars['String']>;
  property?: Maybe<Property>;
  purchaseOrder?: Maybe<PurchaseOrder>;
  refund: Scalars['Float'];
  state?: Maybe<PaymentStateEnum>;
};

/** The connection type for PaymentsTable. */
export type PaymentsTableConnection = {
  __typename?: 'PaymentsTableConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PaymentsTableEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PaymentsTable>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PaymentsTableEdge = {
  __typename?: 'PaymentsTableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PaymentsTable>;
};

export type PaymentsTableFilterInput = {
  /** Filters by category of the payment */
  category?: InputMaybe<CategoryEnum>;
  /** Filters payments created on this exact date */
  date?: InputMaybe<Scalars['ISO8601Date']>;
  /** Filters payments created after or exactly on this date */
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Only include payments from this payment type */
  paymentType?: InputMaybe<PayTypeEnum>;
  /** Filters payments by association with a recurring invoice */
  recurringInvoiceId?: InputMaybe<Scalars['ID']>;
  /** Search by word */
  search?: InputMaybe<Scalars['String']>;
  /** Filters payments created before or exactly on this date  */
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Filters by status of the payment */
  status?: InputMaybe<PaymentStateEnum>;
};

export type PromoCodeValidatorInput = {
  /** Payer email */
  payerEmail: Scalars['Email'];
  /** Discount promo code */
  promoCode: Scalars['String'];
};

export type PropertiesTableFilterInput = {
  /** Only retrieve properties with at least one active recurring invoice */
  havingRecurringInvoices?: InputMaybe<Scalars['Boolean']>;
  /** Search by name */
  search?: InputMaybe<Scalars['String']>;
};

export type Property = {
  __typename?: 'Property';
  alphanumericCode?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  amountStr?: Maybe<Scalars['String']>;
  balance?: Maybe<Balance>;
  communityAddress: Scalars['String'];
  communityName?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyEnum>;
  guestPaymentLogId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  mainRecurringInvoice?: Maybe<RecurringInvoice>;
  name: Scalars['String'];
  nickname: Scalars['String'];
  payee?: Maybe<Payee>;
  payeeId?: Maybe<Scalars['ID']>;
  payer?: Maybe<Payer>;
  payerId?: Maybe<Scalars['ID']>;
};

export type PurchaseOrder = {
  __typename?: 'PurchaseOrder';
  cardNumber?: Maybe<Scalars['String']>;
  cardType?: Maybe<CardTypeEnum>;
  id: Scalars['ID'];
  orderStatus: PurchaseOrderStatusEnum;
  paymentStatus: Scalars['String'];
  paymentType?: Maybe<Scalars['String']>;
  totalAmount: Scalars['Float'];
  totalAmountStr: Scalars['String'];
};

export enum PurchaseOrderStatusEnum {
  Cancelled = 'cancelled',
  Created = 'created',
  Expired = 'expired',
  Failed = 'failed',
  Fullfilled = 'fullfilled',
  Refund = 'refund',
  Rejected = 'rejected'
}

export type Query = {
  __typename?: 'Query';
  banksTable: BanksTableConnection;
  calculateAutomaticPaymentDates: AutomaticPaymentDates;
  calculatePaymentAmount: PaymentAmount;
  campaign?: Maybe<Campaign>;
  campaignEffectiveDates?: Maybe<Campaign>;
  campaigns?: Maybe<CampaignsTableConnection>;
  communityExistsInComunidadFeliz?: Maybe<CommunityExistsInComunidadFeliz>;
  couponsTable?: Maybe<CouponsTableConnection>;
  dispersion: Dispersion;
  dispersionCalendarEntriesTable?: Maybe<DispersionCalendarEntriesTableConnection>;
  dispersions?: Maybe<DispersionsTableConnection>;
  enrollCardLink: Scalars['String'];
  generateTransactionsReport?: Maybe<TransactionsReportPayload>;
  payeeProperties?: Maybe<PayeePropertyTableConnection>;
  payeeProperty?: Maybe<Property>;
  payeePropertyFromAlphanumeric?: Maybe<Property>;
  payer?: Maybe<Payer>;
  payerProperties?: Maybe<PayerPropertyTableConnection>;
  payment?: Maybe<Payment>;
  paymentGateways: Array<PaymentGateway>;
  paymentsTable: PaymentsTableConnection;
  possiblePaymentDate: Scalars['ISO8601Date'];
  promoCodeValidator: CouponValidatorResponse;
  property?: Maybe<Property>;
  purchaseOrderDetails: PurchaseOrder;
  smartLinkInvoice?: Maybe<DecodedInvoice>;
  smartLinkPaymentDataPreload: DecodedPayment;
  userCards?: Maybe<Array<Card>>;
  userPayCommission: Scalars['Boolean'];
  userProfile?: Maybe<User>;
  usersTable?: Maybe<UsersTableConnection>;
};


export type QueryBanksTableArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCalculateAutomaticPaymentDatesArgs = {
  paymentDayIndex: Scalars['Int'];
};


export type QueryCalculatePaymentAmountArgs = {
  input: CalculatePaymentAmountInput;
};


export type QueryCampaignArgs = {
  id: Scalars['ID'];
};


export type QueryCampaignEffectiveDatesArgs = {
  email: Scalars['String'];
};


export type QueryCampaignsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCommunityExistsInComunidadFelizArgs = {
  tin: Scalars['String'];
};


export type QueryCouponsTableArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<CouponsTableFilterInput>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryDispersionArgs = {
  id: Scalars['ID'];
};


export type QueryDispersionCalendarEntriesTableArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<DateRangeFilterInput>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryDispersionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<DateRangeFilterInput>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryEnrollCardLinkArgs = {
  input: EnrollCardInput;
};


export type QueryGenerateTransactionsReportArgs = {
  paymentsIds: Array<Scalars['ID']>;
};


export type QueryPayeePropertiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<PropertiesTableFilterInput>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryPayeePropertyArgs = {
  id: Scalars['ID'];
};


export type QueryPayeePropertyFromAlphanumericArgs = {
  alphanumericCode: Scalars['String'];
};


export type QueryPayerPropertiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<PropertiesTableFilterInput>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryPaymentArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentsTableArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<PaymentsTableFilterInput>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryPromoCodeValidatorArgs = {
  input: PromoCodeValidatorInput;
};


export type QueryPropertyArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseOrderDetailsArgs = {
  id: Scalars['ID'];
};


export type QuerySmartLinkInvoiceArgs = {
  token: Scalars['String'];
};


export type QuerySmartLinkPaymentDataPreloadArgs = {
  token: Scalars['String'];
};


export type QueryUserCardsArgs = {
  input: UserCardsInput;
};


export type QueryUserPayCommissionArgs = {
  input: UserPayCommissionInput;
};


export type QueryUsersTableArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<UsersTableFilterInput>;
  last?: InputMaybe<Scalars['Int']>;
};

export type RecurringInvoice = {
  __typename?: 'RecurringInvoice';
  active: Scalars['Boolean'];
  amount: Scalars['Float'];
  amountStr: Scalars['String'];
  convertedAmount?: Maybe<Scalars['Float']>;
  currency: CurrencyEnum;
  dayIndicator: Scalars['Int'];
  endDate?: Maybe<Scalars['ISO8601Date']>;
  frequency: FrequencyEnum;
  id: Scalars['ID'];
  lastInvoice?: Maybe<Invoice>;
  payer?: Maybe<Payer>;
  property?: Maybe<RecurringInvoicePropertyPayload>;
  startDate: Scalars['ISO8601Date'];
};

export type RecurringInvoiceCreateAssociationsInput = {
  /** Data for payer creation */
  payer: CreatePayerInput;
  /** Data for property creation */
  property: CreatePropertyInput;
};

export type RecurringInvoiceDataInput = {
  /** Amount of the recurring invoice */
  amount: Scalars['Float'];
  /** Currency of the recurring invoice */
  currency: CurrencyEnum;
  /** The invoice will be due this day every period */
  dayIndicator: Scalars['Int'];
  /** Do not create more invoices after this date */
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Frequency of the invoice */
  frequency: FrequencyEnum;
  /** Create an immediate invoice */
  immediateInvoice: Scalars['Boolean'];
  /** ID of the payee */
  payeeId: Scalars['ID'];
  /** ID of the payer */
  payerId?: InputMaybe<Scalars['ID']>;
  /** ID of the property */
  propertyId?: InputMaybe<Scalars['ID']>;
  /** Create invoices from this date */
  startDate: Scalars['ISO8601Date'];
};

export type RecurringInvoiceLastInvoicePayload = {
  __typename?: 'RecurringInvoiceLastInvoicePayload';
  amountStr: Scalars['String'];
  id: Scalars['ID'];
  issuedAt: Scalars['ISO8601Date'];
  status: InvoiceStatusEnum;
};

export type RecurringInvoicePropertyPayload = {
  __typename?: 'RecurringInvoicePropertyPayload';
  communityAddress: Scalars['String'];
  communityName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  nickname: Scalars['String'];
};

export type RegisterPayerPaymentDataInput = {
  /** Data for bank data update */
  bankData: BankDataInput;
  /** Data for payee creation or asociation */
  payee: CreatePayeeInput;
  /** Data for property creation */
  property: CreatePropertyInput;
  /** Active status of the recurring invoice */
  recurringInvoiceActive?: InputMaybe<Scalars['Boolean']>;
  /** The invoice will be due this day every period */
  recurringInvoiceDayIndicator?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of RegisterPayerPaymentData. */
export type RegisterPayerPaymentDataPayload = {
  __typename?: 'RegisterPayerPaymentDataPayload';
  data: RegisterPayerPropertyPayload;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type RegisterPayerPropertyPayload = {
  __typename?: 'RegisterPayerPropertyPayload';
  convertedAmount?: Maybe<Scalars['Float']>;
  convertedAmountStr?: Maybe<Scalars['String']>;
  payeeId?: Maybe<Scalars['ID']>;
  payerId?: Maybe<Scalars['ID']>;
  propertyId?: Maybe<Scalars['ID']>;
};

export type RegisterSuccessfulPaymentInput = {
  /** Data for resuming payment URL */
  encryptedData: Scalars['String'];
  /** Purchase order of payment */
  externalPurchaseOrderId: Scalars['ID'];
  /** Type of payment */
  payType?: InputMaybe<PayTypeEnum>;
  /** The new status */
  status: AttemptStatusEnum;
};

/** Autogenerated return type of RegisterSuccessfulPayment. */
export type RegisterSuccessfulPaymentPayload = {
  __typename?: 'RegisterSuccessfulPaymentPayload';
  data: Payment;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Autogenerated return type of SendUserPasswordRecoveryEmail. */
export type SendUserPasswordRecoveryEmailPayload = {
  __typename?: 'SendUserPasswordRecoveryEmailPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SendVoucherMailInput = {
  /** Email of the recipient */
  email: Scalars['String'];
  /** Data for resuming payment URL */
  encryptedData: Scalars['String'];
  /** Payment ID */
  paymentId: Scalars['ID'];
};

/** Autogenerated return type of SendVoucherMail. */
export type SendVoucherMailPayload = {
  __typename?: 'SendVoucherMailPayload';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of SignInBackOffice. */
export type SignInBackOfficePayload = {
  __typename?: 'SignInBackOfficePayload';
  data?: Maybe<SignInPayload>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SignInInput = {
  email: Scalars['Email'];
  password: Scalars['String'];
};

export type SignInOrSignUpUserOauthInput = {
  oauthToken: Scalars['String'];
  provider: OauthProviderEnum;
  role?: InputMaybe<UserRoleEnum>;
};

/** Autogenerated return type of SignInOrSignUpUserOauth. */
export type SignInOrSignUpUserOauthPayload = {
  __typename?: 'SignInOrSignUpUserOauthPayload';
  data: User;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SignInPayload = {
  __typename?: 'SignInPayload';
  token: Scalars['String'];
};

export type SignInUserInput = {
  email: Scalars['Email'];
  password: Scalars['String'];
  role?: InputMaybe<UserRoleEnum>;
};

/** Autogenerated return type of SignInUser. */
export type SignInUserPayload = {
  __typename?: 'SignInUserPayload';
  data: User;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Autogenerated return type of SignOutUser. */
export type SignOutUserPayload = {
  __typename?: 'SignOutUserPayload';
  data: User;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SignUpUserInput = {
  email: Scalars['Email'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRoleEnum>;
  tin?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SignUpUser. */
export type SignUpUserPayload = {
  __typename?: 'SignUpUserPayload';
  data: User;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Autogenerated return type of SmartLinkOnboarding. */
export type SmartLinkOnboardingPayload = {
  __typename?: 'SmartLinkOnboardingPayload';
  data: User;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Autogenerated return type of SmartLinkPaymentLog. */
export type SmartLinkPaymentLogPayload = {
  __typename?: 'SmartLinkPaymentLogPayload';
  data?: Maybe<DecodedPaymentLog>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export enum SpecialRuleEnum {
  CfAfCampaign = 'CF_AF_CAMPAIGN',
  FirstPayment = 'FIRST_PAYMENT',
  NoRules = 'NO_RULES'
}

export enum StepEnum {
  Paid = 'PAID',
  PayeeData = 'PAYEE_DATA',
  PaymentMethod = 'PAYMENT_METHOD',
  PropertyData = 'PROPERTY_DATA',
  Register = 'REGISTER',
  Visit = 'VISIT'
}

export type SubmitPayment = {
  __typename?: 'SubmitPayment';
  guestPaymentLogId?: Maybe<Scalars['ID']>;
  paymentId: Scalars['Int'];
  purchaseOrderId: Scalars['Int'];
  redirectUrl: Scalars['String'];
  status: Scalars['String'];
  totalAmount: Scalars['Float'];
  totalAmountStr: Scalars['String'];
};

export type SubmitPaymentInput = {
  /** Card id from Payments Portal */
  cardId?: InputMaybe<Scalars['ID']>;
  /** Coupons and email */
  couponsData?: InputMaybe<Array<CouponsDetailsInput>>;
  /** Flag to create a new guest payment log */
  createLog?: InputMaybe<Scalars['Boolean']>;
  /** Data for resuming payment URL */
  encryptedData?: InputMaybe<Scalars['String']>;
  /** ID of the guest payment log */
  guestPaymentLogId?: InputMaybe<Scalars['ID']>;
  /** New payment data */
  payment: CreatePaymentInput;
  /** Payment gateway ID */
  paymentGatewayId: Scalars['ID'];
  /** Total payment value commission included */
  totalAmount: Scalars['Float'];
};

/** Autogenerated return type of SubmitPaymentOneclick. */
export type SubmitPaymentOneclickPayload = {
  __typename?: 'SubmitPaymentOneclickPayload';
  data: SubmitPayment;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Autogenerated return type of SubmitPayment. */
export type SubmitPaymentPayload = {
  __typename?: 'SubmitPaymentPayload';
  data: SubmitPayment;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ToggleActiveCampaign. */
export type ToggleActiveCampaignPayload = {
  __typename?: 'ToggleActiveCampaignPayload';
  data: Campaign;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type TransactionsReportPayload = {
  __typename?: 'TransactionsReportPayload';
  base64Excel?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
};

export type UpdateCampaignInput = {
  /** Allow commission negative */
  allowDiscountExceedCommission?: InputMaybe<Scalars['Boolean']>;
  /** Amount of the discount */
  discountAmount?: InputMaybe<Scalars['Float']>;
  /** Type of discount */
  discountType?: InputMaybe<DiscountTypeEnum>;
  /** End date of the campaign */
  endAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Exclusive campaign */
  exclusive?: InputMaybe<Scalars['Boolean']>;
  /** ID of the campaign */
  id: Scalars['ID'];
  /** Max accumulated discount */
  maxAccumulatedDiscount?: InputMaybe<Scalars['Float']>;
  /** Max discount per coupon */
  maxCouponDiscount?: InputMaybe<Scalars['Float']>;
  /** Max quantity of coupons */
  maxQuantityCoupons?: InputMaybe<Scalars['Int']>;
  /** Max discount per coupon */
  maxUsesPerCoupon?: InputMaybe<Scalars['Int']>;
  /** Max discount per user */
  maxUsesPerUser?: InputMaybe<Scalars['Int']>;
  /** Min amount to apply discount */
  minAmountApplyDiscount?: InputMaybe<Scalars['Float']>;
  /** Start date of the campaign */
  startAt?: InputMaybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of UpdateCampaign. */
export type UpdateCampaignPayload = {
  __typename?: 'UpdateCampaignPayload';
  data: Campaign;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdateCouponInput = {
  discountAmount?: InputMaybe<Scalars['Float']>;
  expirationAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  userEmail?: InputMaybe<Scalars['Email']>;
};

/** Autogenerated return type of UpdateCoupon. */
export type UpdateCouponPayload = {
  __typename?: 'UpdateCouponPayload';
  data: Coupon;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdateDispersionCalendarEntryInput = {
  endAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  paymentDate?: InputMaybe<Scalars['ISO8601Date']>;
  refundDate?: InputMaybe<Scalars['ISO8601Date']>;
  startAt?: InputMaybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of UpdateDispersionCalendarEntry. */
export type UpdateDispersionCalendarEntryPayload = {
  __typename?: 'UpdateDispersionCalendarEntryPayload';
  data: DispersionCalendarEntry;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdateDispersionStateInput = {
  id: Scalars['ID'];
  state: DispersionStateEnum;
};

/** Autogenerated return type of UpdateDispersionState. */
export type UpdateDispersionStatePayload = {
  __typename?: 'UpdateDispersionStatePayload';
  data: Dispersion;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdatePayeeBankDataInput = {
  /** Name of the bank */
  bank: BankEnum;
  /** Account number at bank */
  bankAccountNumber: Scalars['String'];
  /** Account type at bank */
  bankAccountType: BankAccountTypeEnum;
  /** Payee ID */
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdatePayeeBankData. */
export type UpdatePayeeBankDataPayload = {
  __typename?: 'UpdatePayeeBankDataPayload';
  data: Payee;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdatePayeeInput = {
  /** Name of the bank */
  bank: BankEnum;
  /** Account number at bank */
  bankAccountNumber: Scalars['String'];
  /** Account type at bank */
  bankAccountType: BankAccountTypeEnum;
  /** Email of the payee */
  email: Scalars['String'];
  /** Name of the payee */
  name: Scalars['String'];
  /** TIN of the payee */
  tin: Scalars['String'];
};

export type UpdatePayerInput = {
  /** Email of the payer */
  email?: InputMaybe<Scalars['String']>;
  /** Name of the payer */
  name?: InputMaybe<Scalars['String']>;
  /** Phone of the payer */
  phone?: InputMaybe<Scalars['String']>;
  /** TIN of the payer */
  tin?: InputMaybe<Scalars['String']>;
};

export type UpdatePaymentInput = {
  /** Dispersion of the payment */
  dispersionId?: InputMaybe<Scalars['ID']>;
  /** ID of the payment */
  id: Scalars['ID'];
  /** Payee of the payment */
  payee?: InputMaybe<UpdatePayeeInput>;
  /** Payer of the payment */
  payer?: InputMaybe<UpdatePayerInput>;
  /** Property of the payment */
  property?: InputMaybe<UpdatePropertyInput>;
};

/** Autogenerated return type of UpdatePayment. */
export type UpdatePaymentPayload = {
  __typename?: 'UpdatePaymentPayload';
  data: Payment;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdateProfileInput = {
  /** Last name of the user */
  lastName?: InputMaybe<Scalars['String']>;
  /** Name of the user */
  name?: InputMaybe<Scalars['String']>;
  /** Phone of the user */
  phone?: InputMaybe<Scalars['String']>;
  /** TIN of the user */
  tin?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateProfile. */
export type UpdateProfilePayload = {
  __typename?: 'UpdateProfilePayload';
  data: User;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdatePropertyInput = {
  /** Alphanumeric code of the property */
  alphanumericCode?: InputMaybe<Scalars['String']>;
  /** Amount of the property */
  amount?: InputMaybe<Scalars['Float']>;
  /** Address of the community */
  communityAddress?: InputMaybe<Scalars['String']>;
  /** Name of the community */
  communityName?: InputMaybe<Scalars['String']>;
  /** Currency of the property */
  currency?: InputMaybe<CurrencyEnum>;
  /** The invoice will be due this day every period */
  dayIndicator?: InputMaybe<Scalars['Int']>;
  /** Property id */
  id?: InputMaybe<Scalars['ID']>;
  /** Name of the property */
  name?: InputMaybe<Scalars['String']>;
  /** Nickname of the property */
  nickname?: InputMaybe<Scalars['String']>;
  /** Active status of the recurring invoice */
  recurringInvoiceActive?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateProperty. */
export type UpdatePropertyPayload = {
  __typename?: 'UpdatePropertyPayload';
  data: Property;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdateRecurringInvoiceInput = {
  /** Amount of the recurring invoice */
  amount?: InputMaybe<Scalars['Float']>;
  /** Currency of the recurring invoice */
  currency?: InputMaybe<CurrencyEnum>;
  /** The invoice will be due this day every period */
  dayIndicator?: InputMaybe<Scalars['Int']>;
  /** Recurring invoice id */
  id: Scalars['ID'];
  /** Payer of the payment */
  payer?: InputMaybe<UpdatePayerInput>;
  /** Property of the payment */
  property?: InputMaybe<UpdatePropertyInput>;
};

/** Autogenerated return type of UpdateRecurringInvoice. */
export type UpdateRecurringInvoicePayload = {
  __typename?: 'UpdateRecurringInvoicePayload';
  data: RecurringInvoice;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdateUserPasswordInput = {
  /** New password */
  password: Scalars['String'];
  /** New password confirmation */
  passwordConfirmation: Scalars['String'];
};

/** Autogenerated return type of UpdateUserPassword. */
export type UpdateUserPasswordPayload = {
  __typename?: 'UpdateUserPasswordPayload';
  data: User;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdateUserPasswordWithTokenInput = {
  /** New password */
  password: Scalars['String'];
  /** New password confirmation */
  passwordConfirmation: Scalars['String'];
  /** Token sent via email */
  resetPasswordToken: Scalars['String'];
};

/** Autogenerated return type of UpdateUserPasswordWithToken. */
export type UpdateUserPasswordWithTokenPayload = {
  __typename?: 'UpdateUserPasswordWithTokenPayload';
  data: User;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdateUserPayCommissionInput = {
  /** ID of the user */
  id: Scalars['ID'];
  /** Pay commission */
  payCommission: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateUserPayCommission. */
export type UpdateUserPayCommissionPayload = {
  __typename?: 'UpdateUserPayCommissionPayload';
  data: User;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdateUserPayeeInput = {
  /** Name of the bank */
  bank?: InputMaybe<BankEnum>;
  /** Account number at bank */
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  /** Account type at bank */
  bankAccountType?: InputMaybe<BankAccountTypeEnum>;
  /** Email of the payee */
  email?: InputMaybe<Scalars['Email']>;
  /** ID of the payee */
  id?: InputMaybe<Scalars['ID']>;
  /** Name of the payee */
  name?: InputMaybe<Scalars['String']>;
  /** TIN of the payee */
  tin?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateUserPayee. */
export type UpdateUserPayeePayload = {
  __typename?: 'UpdateUserPayeePayload';
  data: Payee;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateUserRole. */
export type UpdateUserRolePayload = {
  __typename?: 'UpdateUserRolePayload';
  data: User;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  credentials?: Maybe<Credential>;
  email: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  payCommission: Scalars['Boolean'];
  payee?: Maybe<Payee>;
  payer?: Maybe<Payer>;
  phone: Scalars['String'];
  role: UserRoleEnum;
  tin: Scalars['String'];
};

export enum UserCardTypeEnum {
  AmericanExpress = 'AmericanExpress',
  MasterCard = 'MasterCard',
  Otro = 'Otro',
  Prepago = 'Prepago',
  RedCompra = 'RedCompra',
  Visa = 'Visa'
}

export type UserCardsInput = {
  /** Payment gateway ID on Payments Portal */
  paymentGatewayId: Scalars['ID'];
  /** Includes automatic payment info for every card if present */
  propertyId?: InputMaybe<Scalars['ID']>;
};

export type UserPayCommissionInput = {
  /** Name of the bank */
  bank: BankEnum;
  /** Account number at bank */
  bankAccountNumber: Scalars['String'];
  /** TIN of the payee */
  tin: Scalars['String'];
};

export enum UserRoleEnum {
  Payee = 'PAYEE',
  Payer = 'PAYER',
  PayerPayee = 'PAYER_PAYEE'
}

export type UsersTable = {
  __typename?: 'UsersTable';
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  payCommission: Scalars['Boolean'];
  propertiesCount: Scalars['Int'];
};

/** The connection type for UsersTable. */
export type UsersTableConnection = {
  __typename?: 'UsersTableConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UsersTableEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UsersTable>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UsersTableEdge = {
  __typename?: 'UsersTableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<UsersTable>;
};

export type UsersTableFilterInput = {
  /** Filters users with properties */
  haveProperties?: InputMaybe<Scalars['Boolean']>;
  /** Search by word */
  search?: InputMaybe<Scalars['String']>;
};

export type UtmLog = {
  __typename?: 'UtmLog';
  campaign?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  entryAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  lastStep: StepEnum;
  medium?: Maybe<Scalars['String']>;
  payerId?: Maybe<Scalars['ID']>;
  paymentId?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of VoidPayment. */
export type VoidPaymentPayload = {
  __typename?: 'VoidPaymentPayload';
  data: Payment;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};
