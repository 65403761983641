export function getMonths(locale: Intl.LocalesArgument) {
  return Array.from({ length: 12 }, (_, month) => {
    const date = new Date(2000, month, 1);
    const monthName = date.toLocaleString(locale, { month: "long" });
    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
  });
}

export function getMonthsWithKey(locale: Intl.LocalesArgument = "es") {
  const monthsInLanguage = getMonths(locale);
  const arrayOfMonthObjects = monthsInLanguage.map((monthName, index) => ({
    key: (index + 1).toString(),
    label: monthName,
  }));
  return arrayOfMonthObjects;
}

export function getMonthDate(
  monthNumber: number,
  locale: Intl.LocalesArgument = "es"
) {
  if (!monthNumber) return "";
  const monthName = new Date(2000, monthNumber - 1, 1).toLocaleString(locale, {
    month: "long",
  });
  return monthName.charAt(0).toUpperCase() + monthName.slice(1);
}

export const getPeriodDateFromMonth = (month: number) => {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  date.setUTCMonth(month);
  date.setUTCDate(1);
  return date.toISOString().slice(0, 10);
};

export const getMonthFromPeriodDate = (periodDate: string) => {
  return new Date(periodDate).getUTCMonth() + 1;
};
