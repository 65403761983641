export const americanCupCountries = [
  "AR",
  "BR",
  "BO",
  "CA",
  "CL",
  "CO",
  "CR",
  "EC",
  "US",
  "JM",
  "MX",
  "PA",
  "PY",
  "PE",
  "UY",
  "VE",
];

export const minimumAmount = 250_000;
export const dateRange = new Date() < new Date("2024-07-04");
export const americanCupTermsUrl =
  "https://www.arriendafeliz.cl/post/terminos-y-condiciones-del-concurso-campeon-copa-america";
