import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CARDS_SLICE_NAME } from "store/constants";
import { AfCard } from "types";

const initialState = {
  createdCard: {
    lastDigits: "",
    externalId: "",
  },
};

const cardsSlice = createSlice({
  name: CARDS_SLICE_NAME,
  initialState,
  reducers: {
    setCreatedCard: (state, action: PayloadAction<Partial<AfCard>>) => {
      state.createdCard.lastDigits = action.payload.lastDigits || "";
      state.createdCard.externalId = action.payload.externalId || "";
    },
  },
});

export const { setCreatedCard } = cardsSlice.actions;

export default cardsSlice;
