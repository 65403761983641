/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FORM_SLICE_NAME } from "store/constants";
import { CategoryEnum, PaymentGatewayEnum } from "types/schema";

type FormState = {
  alphanumericCode: string;
  bankName?: string;
  category: CategoryEnum | null;
  commissionAmount: number;
  convertedAmount?: string;
  date?: string;
  discountAmount?: number;
  disabledInputs?: boolean;
  invoiceId?: string;
  month?: string;
  payeeId?: string;
  payerId?: string;
  paymentGateway?: PaymentGatewayEnum;
  paymentGatewayId: string;
  propertyId?: string;
  propertyUserId?: string;
  promoCode?: string;
  termsAndConditions: boolean;
  totalAmount: number;
};

const initialState: FormState = {
  alphanumericCode: "",
  bankName: "",
  convertedAmount: "0",
  category: CategoryEnum.Lease,
  commissionAmount: 0,
  discountAmount: 0,
  disabledInputs: false,
  invoiceId: "",
  month: "",
  payeeId: "",
  payerId: "",
  paymentGateway: PaymentGatewayEnum.Webpayplus,
  paymentGatewayId: "",
  propertyId: "",
  propertyUserId: "",
  termsAndConditions: false,
  totalAmount: 0,
};

const formSlice = createSlice({
  name: FORM_SLICE_NAME,
  initialState,
  reducers: {
    setForm: (_state, action: PayloadAction<FormState>) => action.payload,
    setAlphanumericCode: (state, action: PayloadAction<string>) => {
      state.alphanumericCode = action.payload;
    },
    setBankName: (state, action: PayloadAction<string>) => {
      state.bankName = action.payload;
    },
    setCommissionAmount: (state, action: PayloadAction<number>) => {
      state.commissionAmount = action.payload;
    },
    setTotalAmount: (state, action: PayloadAction<number>) => {
      state.totalAmount = action.payload;
    },
    setDiscountAmount: (state, action: PayloadAction<number>) => {
      state.discountAmount = action.payload;
    },
    setPaymentGateway: (state, action: PayloadAction<PaymentGatewayEnum>) => {
      state.paymentGateway = action.payload;
    },
    setPaymentGatewayId: (state, action: PayloadAction<string>) => {
      state.paymentGatewayId = action.payload;
    },
    setTermsAndConditions: (state, action: PayloadAction<boolean>) => {
      state.termsAndConditions = action.payload;
    },
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    setPayeeId: (state, action: PayloadAction<string>) => {
      state.payeeId = action.payload;
    },
    setPropertyId: (state, action: PayloadAction<string>) => {
      state.propertyId = action.payload;
    },
    setMonth: (state, action: PayloadAction<string>) => {
      state.month = action.payload;
    },
    setCategory: (state, action: PayloadAction<CategoryEnum>) => {
      state.category = action.payload;
    },
    setPayerId: (state, action: PayloadAction<string>) => {
      state.payerId = action.payload;
    },
    setPromoCode: (state, action: PayloadAction<string>) => {
      state.promoCode = action.payload;
    },
    setDisabledInputs: (state, action: PayloadAction<boolean>) => {
      state.disabledInputs = action.payload;
    },
    clearForm: () => initialState,
    updateField: <T extends keyof FormState>(
      state: FormState,
      action: PayloadAction<{ field: T; value: FormState[T] }>
    ) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    fillState: (state, { payload }: PayloadAction<Partial<FormState>>) => {
      state.commissionAmount =
        payload.commissionAmount || initialState.commissionAmount;
      state.totalAmount = payload.totalAmount || initialState.totalAmount;
      state.paymentGatewayId =
        payload.paymentGatewayId || initialState.paymentGatewayId;
      state.termsAndConditions =
        payload.termsAndConditions || initialState.termsAndConditions;
      state.payeeId = payload.payeeId;
      state.propertyId = payload.propertyId;
      state.propertyUserId = payload.propertyUserId;
      state.category = payload.category || initialState.category;
      state.month = payload.month || initialState.month;
      state.payerId = payload.payerId;
      state.disabledInputs = payload.disabledInputs;
      state.invoiceId = payload.invoiceId;
    },
  },
});

export const {
  clearForm,
  fillState,
  setAlphanumericCode,
  setBankName,
  setCategory,
  setCommissionAmount,
  setDate,
  setDiscountAmount,
  setDisabledInputs,
  setForm,
  setMonth,
  setPayeeId,
  setPayerId,
  setPaymentGateway,
  setPaymentGatewayId,
  setPromoCode,
  setPropertyId,
  setTermsAndConditions,
  setTotalAmount,
  updateField,
} = formSlice.actions;

export default formSlice;
