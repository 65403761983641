/* eslint-disable no-param-reassign */
/* linter rule not needed since redux toolkit uses Immer internally */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { OWNER_AUTH_SLICE_NAME } from "store/constants";
import { Credential, UserRoleEnum } from "types";

export type OwnerAuthState = { credentials: Credential } & {
  role?: UserRoleEnum;
};

const initialState: OwnerAuthState = {
  credentials: {
    accessToken: "",
    expiry: 0,
    uid: "",
    client: "",
    tokenType: "",
  },
  role: UserRoleEnum.Payer,
};

const ownerAuthSlice = createSlice({
  name: OWNER_AUTH_SLICE_NAME,
  initialState,
  reducers: {
    login: (state, action: PayloadAction<Credential>) => {
      state.credentials = action.payload;
    },
    logout: (state) => {
      state.credentials = initialState.credentials;
    },
    setRole: (state, action: PayloadAction<UserRoleEnum>) => {
      state.role = action.payload;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      state.credentials = action.payload[OWNER_AUTH_SLICE_NAME].credentials;
    },
  },
});

export const { login, logout, setRole } = ownerAuthSlice.actions;

export default ownerAuthSlice;
