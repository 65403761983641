import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "features/backOffice/login/login.slice";
import automaticPaymentSlice from "features/automaticPayment/index.slice";
import bciSlice from "features/bci/index.slice";
import cardsSlice from "features/form/Cards/index.slice";
import formSlice from "features/form/form.slice";
import guestPaymentLogIdSlice from "features/form/guestPaymentLogId.slice";
import localeSlice from "i18n/locale.slice";
import modalsSlice from "modals/index.slice";
import ownerAuthSlice from "features/owner/index.slice";
import registerPayerPaymentSlice from "features/lessee/onboarding/index.slice";
import successfulPaymentSlice from "features/successfulPayment/index.slice";
import unregisteredFormSlice from "features/form/unregisteredForm.slice";
import utmSlice from "features/utm/index.slice";
import { api as residentsApi } from "./residentsApi";
import { api } from "./baseApi";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [authSlice.name]: authSlice.reducer,
  [automaticPaymentSlice.name]: automaticPaymentSlice.reducer,
  [bciSlice.name]: bciSlice.reducer,
  [cardsSlice.name]: cardsSlice.reducer,
  [formSlice.name]: formSlice.reducer,
  [guestPaymentLogIdSlice.name]: guestPaymentLogIdSlice.reducer,
  [localeSlice.name]: localeSlice.reducer,
  [modalsSlice.name]: modalsSlice.reducer,
  [ownerAuthSlice.name]: ownerAuthSlice.reducer,
  [registerPayerPaymentSlice.name]: registerPayerPaymentSlice.reducer,
  [residentsApi.reducerPath]: residentsApi.reducer,
  [successfulPaymentSlice.name]: successfulPaymentSlice.reducer,
  [unregisteredFormSlice.name]: unregisteredFormSlice.reducer,
  [utmSlice.name]: utmSlice.reducer,
});

export default rootReducer;
