import { setCategory } from "features/form/form.slice";
import { useRouter } from "next/router";
import { useAppDispatch, useAppSelector } from "store";
import { CategoryEnum } from "types";
import {
  CategoryPathEnum,
  StepPathEnum,
  getKeyFromEnumValue,
} from "utils/routes";

export default function useCategoryKeyPath() {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { category: categoryParam } = router.query as {
    category: CategoryPathEnum;
    step: StepPathEnum;
  };
  const form = useAppSelector((state) => state.form);

  if (form.category) {
    return form.category;
  }

  if (categoryParam) {
    const category = getKeyFromEnumValue(categoryParam, CategoryPathEnum);
    dispatch(setCategory(category as CategoryEnum));
    return category;
  }

  return "COMMON_EXPENSE";
}
