/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";
import { HYDRATE } from "next-redux-wrapper";
import { CategoryEnum } from "types";

export const client = new GraphQLClient(
  process.env.NEXT_PUBLIC_API_URL as string
);
export const baseQuery = graphqlRequestBaseQuery({
  url: process.env.NEXT_PUBLIC_API_URL as string,
  prepareHeaders: (headers: Headers, { getState }): Headers => {
    const { payerEmail, category } = (getState() as any).form;
    const { category: propertyCategory } = (getState() as any)
      .lessee_register_property;
    const { credentials } = (getState() as any).owner_auth;
    const { tokenType, expiry, uid, accessToken: userToken } = credentials;
    const { token: backOfficeToken } = (getState() as any).auth;

    headers.set("CF_TOKEN", process.env.NEXT_PUBLIC_CF_TOKEN as string);
    headers.set(
      "PAYMENTS_OFFICE",
      category === CategoryEnum.CommonExpense ||
        propertyCategory === CategoryEnum.CommonExpense
        ? "original"
        : "alternative"
    );
    if (payerEmail) {
      headers.set("PAYEREMAIL", payerEmail);
    }
    if (backOfficeToken && !userToken) {
      headers.set("BACKOFFICE_TOKEN", backOfficeToken);
    }
    if (userToken) {
      headers.set("ACCESS_TOKEN", userToken);
      headers.set("TOKEN_TYPE", tokenType);
      headers.set("EXPIRY", expiry);
      headers.set("UID", uid);
      headers.set("CLIENT", credentials.client);
    }
    return headers;
  },
});

export const api = createApi({
  baseQuery,
  reducerPath: "api",
  // eslint-disable-next-line consistent-return
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  tagTypes: [],
  endpoints: () => ({}),
});
