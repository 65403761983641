/* eslint-disable no-shadow */
import { I18nActiveNamespaces } from "i18n";
import { ChangeEvent } from "react";
import {
  SignUpUserInput,
  CreatePropertyInput,
  CreatePayerInput,
  BankDataInput,
  RecurringInvoiceDataInput,
  CreatePayeeInput,
} from "./schema";

export type BaseConfig = {
  i18nNamespaces: I18nActiveNamespaces;
};

export type EventType = ChangeEvent<HTMLInputElement>;

export type InputKeys =
  | keyof SignUpUserInput
  | keyof CreatePropertyInput
  | keyof CreatePayerInput
  | keyof BankDataInput
  | keyof RecurringInvoiceDataInput
  | keyof CreatePayeeInput
  | string;

export enum ValidationType {
  PASSWORD = "password",
  ALPHANUMERIC_CODE = "alphanumericCode",
  ALPHANUMERIC_CODE_EDIT = "alphanumericCodeEdit",
}

export type ConfirmationModalProps = {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export enum OnboardingStep {
  AccountStep = "account_step",
  PropertyStep = "property_step",
}
